import moment from 'moment';
import 'moment/locale/cs';

export const DT_LOCAL_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DT_LOCAL_FORMAT_PARSE = 'DD.MM.YYYY HH:mm';
export const DT_PRINT_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const DT_SERVER_FORMAT_PARSE = 'YYYY-MM-DD HH:mm:ss';
export const DT_SERVER_FORMAT = 'YYYY-MM-DD HH:mm:ssZ';
export const DT_DATE_FORMAT = 'YYYY-MM-DD';
export const DT_TIME_FORMAT = 'HH:mm';

export const timestampToLocalFmt = (timestamp: moment.MomentInput) => {
    return moment(timestamp).format('DD.MM.YYYY HH:mm:ss');
};

export const serverToTimestamp = (dt: moment.MomentInput) => {
    return moment(dt, 'YYYY-MM-DD HH:mm:ss').valueOf();
};

export const serverToLocalDate = (dt: moment.MomentInput) => {
    return moment(dt, DT_SERVER_FORMAT_PARSE).format(DT_LOCAL_FORMAT_PARSE);
}

export const serverToLocalFmt = (dt: moment.MomentInput) => {
    return moment(dt, DT_SERVER_FORMAT_PARSE).format(DT_LOCAL_FORMAT);
};

export const serverToPrintFmt = (dt: moment.MomentInput) => {
    return moment(dt, DT_SERVER_FORMAT_PARSE).format(DT_PRINT_FORMAT);
};

export const toServerFmt = (dt: moment.MomentInput) => {
    return moment(dt).format(DT_SERVER_FORMAT);
};

export const nowInServerFmt = () => {
    return moment().format(DT_SERVER_FORMAT);
};

// --------------------------------------------------------------------

export const localToServerFmt = (dt: moment.MomentInput) => {
    return moment(dt, DT_LOCAL_FORMAT).format(DT_SERVER_FORMAT);
};

export const localUtcToServerFmt = (dt: moment.MomentInput) => {
    return moment.utc(dt, DT_LOCAL_FORMAT).format(DT_SERVER_FORMAT);
};

export const printToServerFmt = (dt: moment.MomentInput) => {
    return moment(dt, DT_PRINT_FORMAT).format(DT_SERVER_FORMAT);
};

export const printUtcToServerFmt = (dt: moment.MomentInput) => {
    return moment.utc(dt, DT_PRINT_FORMAT).format(DT_SERVER_FORMAT);
};

export const toLocalFmt = (dt: moment.MomentInput) => {
    return moment(dt).format(DT_LOCAL_FORMAT);
};

export const fromServerFmt = (dt: moment.MomentInput) => {
    return moment(dt, DT_SERVER_FORMAT_PARSE);
};

export const nowInPrintFmt = () => {
    return moment().format(DT_PRINT_FORMAT);
};

export  const isDateTimeValidPrint = (dt: moment.MomentInput) => {
    return moment(dt, DT_PRINT_FORMAT).isValid();
};


export const secondsToHms = (secs: number) => {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = Math.floor(secs % 60);
    return { hours, minutes, seconds };
}
