import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import AsyncLocalitySelect, { OptionType } from './AsyncLocalitySelect';

//hashovaci funkce
const hashString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash).toString();
};


const InstallationSelectPage = () => {
  // local state
  const [hashedLocalityName, setHashedLocalityName] = useState<string>('');
  const [selectedLocality, setSelectedLocality] = useState<OptionType | null>(null);

  const navigate = useNavigate();

  // Změna výběru v AsyncLocalitySelect
  const handleSelectChange = (newValue: OptionType | null) => {
    setSelectedLocality(newValue);
    setHashedLocalityName(hashString(newValue?.label || ''));
  };

  // Kliknutí na tlačítko
  const handleButtonClick = () => {
    if (selectedLocality) {
      navigate(`/hlaseni/${hashedLocalityName}`);
    }
  };

  return (
    <Container fluid className="d-flex vh-100">
      <Row className="justify-content-center align-items-center w-100">
        <Col md={4} className="text-center">
          <AsyncLocalitySelect onChange={handleSelectChange}/>
          <Button
            variant="primary"
            className="mt-3"
            onClick={handleButtonClick}
            disabled={!selectedLocality}
          >
            Zobrazit hlášení
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default InstallationSelectPage;
