import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appPermissionList: build.query<
      AppPermissionListApiResponse,
      AppPermissionListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    appPermissionUpsert: build.mutation<
      AppPermissionUpsertApiResponse,
      AppPermissionUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/code/${encodeURIComponent(String(queryArg.code))}/text/${encodeURIComponent(String(queryArg.text))}/description/${encodeURIComponent(String(queryArg.description))}`,
        method: "PUT",
      }),
    }),
    appPermissionDelete: build.mutation<
      AppPermissionDeleteApiResponse,
      AppPermissionDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/code/${encodeURIComponent(String(queryArg.code))}`,
        method: "DELETE",
      }),
    }),
    appPermissionSetPermission: build.mutation<
      AppPermissionSetPermissionApiResponse,
      AppPermissionSetPermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/set-permission/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        method: "PUT",
        body: queryArg.appPermissions,
      }),
    }),
    appPermissionSetPermissionCustom: build.mutation<
      AppPermissionSetPermissionCustomApiResponse,
      AppPermissionSetPermissionCustomApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/set-permission-custom/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        method: "PUT",
        body: queryArg.appPermissions,
      }),
    }),
    appPermissionSystemList: build.query<
      AppPermissionSystemListApiResponse,
      AppPermissionSystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/system/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    appPermissionSystemUpsert: build.mutation<
      AppPermissionSystemUpsertApiResponse,
      AppPermissionSystemUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/system/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/code/${encodeURIComponent(String(queryArg.code))}/text/${encodeURIComponent(String(queryArg.text))}/description/${encodeURIComponent(String(queryArg.description))}`,
        method: "PUT",
      }),
    }),
    appPermissionSystemDelete: build.mutation<
      AppPermissionSystemDeleteApiResponse,
      AppPermissionSystemDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/system/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/code/${encodeURIComponent(String(queryArg.code))}`,
        method: "DELETE",
      }),
    }),
    appPermissionSystemSetPermission: build.mutation<
      AppPermissionSystemSetPermissionApiResponse,
      AppPermissionSystemSetPermissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/app-permission/system/set-permission/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        method: "PUT",
        body: queryArg.appPermissions,
      }),
    }),
    audioStorageGeneralFolderList: build.query<
      AudioStorageGeneralFolderListApiResponse,
      AudioStorageGeneralFolderListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/general/folder-list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/folder_name/${encodeURIComponent(String(queryArg.folderName))}`,
      }),
    }),
    audioStorageGeneralSaveToRelation: build.query<
      AudioStorageGeneralSaveToRelationApiResponse,
      AudioStorageGeneralSaveToRelationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/general/save-to-relation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/file_name/${encodeURIComponent(String(queryArg.fileName))}/relation_installation_id/${encodeURIComponent(String(queryArg.relationInstallationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/order_position/${encodeURIComponent(String(queryArg.orderPosition))}/is_public/${encodeURIComponent(String(queryArg.isPublic))}`,
      }),
    }),
    audioStorageUserFolderList: build.query<
      AudioStorageUserFolderListApiResponse,
      AudioStorageUserFolderListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/folder-list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/folder_name/${encodeURIComponent(String(queryArg.folderName))}`,
      }),
    }),
    audioStorageUserFolderCreate: build.query<
      AudioStorageUserFolderCreateApiResponse,
      AudioStorageUserFolderCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/folder-create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/folder_name/${encodeURIComponent(String(queryArg.folderName))}`,
      }),
    }),
    audioStorageUserUsage: build.query<
      AudioStorageUserUsageApiResponse,
      AudioStorageUserUsageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/usage/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    audioStorageUserFileUpload: build.query<
      AudioStorageUserFileUploadApiResponse,
      AudioStorageUserFileUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/file-upload/session_id/${encodeURIComponent(String(queryArg.sessionId))}/file_name/${encodeURIComponent(String(queryArg.fileName))}`,
        body: queryArg.body,
      }),
    }),
    audioStorageUserFileDownload: build.query<
      AudioStorageUserFileDownloadApiResponse,
      AudioStorageUserFileDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/file-download/session_id/${encodeURIComponent(String(queryArg.sessionId))}/file_name/${encodeURIComponent(String(queryArg.fileName))}`,
      }),
    }),
    audioStorageUserItemRename: build.query<
      AudioStorageUserItemRenameApiResponse,
      AudioStorageUserItemRenameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/item-rename/session_id/${encodeURIComponent(String(queryArg.sessionId))}/from_name/${encodeURIComponent(String(queryArg.fromName))}/to_name/${encodeURIComponent(String(queryArg.toName))}`,
      }),
    }),
    audioStorageUserItemDelete: build.query<
      AudioStorageUserItemDeleteApiResponse,
      AudioStorageUserItemDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/item-delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/item_name/${encodeURIComponent(String(queryArg.itemName))}`,
      }),
    }),
    audioStorageUserSaveToRelation: build.query<
      AudioStorageUserSaveToRelationApiResponse,
      AudioStorageUserSaveToRelationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/audio/storage/user/save-to-relation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/file_name/${encodeURIComponent(String(queryArg.fileName))}/relation_installation_id/${encodeURIComponent(String(queryArg.relationInstallationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/order_position/${encodeURIComponent(String(queryArg.orderPosition))}/is_public/${encodeURIComponent(String(queryArg.isPublic))}`,
      }),
    }),
    authenticationLogin: build.mutation<
      AuthenticationLoginApiResponse,
      AuthenticationLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/authentication/login/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_name/${encodeURIComponent(String(queryArg.installationName))}/username/${encodeURIComponent(String(queryArg.username))}`,
        method: "POST",
        body: queryArg.userLoginPassword,
      }),
    }),
    authenticationToken: build.mutation<
      AuthenticationTokenApiResponse,
      AuthenticationTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/authentication/token/session_id/${encodeURIComponent(String(queryArg.sessionId))}/client_id/${encodeURIComponent(String(queryArg.clientId))}`,
        method: "POST",
        body: queryArg.userLoginToken,
      }),
    }),
    authenticationRefeshAccess: build.mutation<
      AuthenticationRefeshAccessApiResponse,
      AuthenticationRefeshAccessApiArg
    >({
      query: (queryArg) => ({
        url: `/api/authentication/refesh-access/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        method: "POST",
      }),
    }),
    authenticationPublicPlanToken: build.mutation<
      AuthenticationPublicPlanTokenApiResponse,
      AuthenticationPublicPlanTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/authentication/public_plan/token/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        method: "POST",
        body: queryArg.userLoginToken,
      }),
    }),
    authenticationSystemLogin: build.mutation<
      AuthenticationSystemLoginApiResponse,
      AuthenticationSystemLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/authentication/system/login/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_name/${encodeURIComponent(String(queryArg.installationName))}/username/${encodeURIComponent(String(queryArg.username))}/password/${encodeURIComponent(String(queryArg.password))}`,
        method: "POST",
      }),
    }),
    deviceDestinationListForInstallations: build.query<
      DeviceDestinationListForInstallationsApiResponse,
      DeviceDestinationListForInstallationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device/destination/list-for-installations/session_id/${encodeURIComponent(String(queryArg.sessionId))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    deviceBackinfoDestinationListForInstallations: build.query<
      DeviceBackinfoDestinationListForInstallationsApiResponse,
      DeviceBackinfoDestinationListForInstallationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device/backinfo-destination/list-for-installations/session_id/${encodeURIComponent(String(queryArg.sessionId))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    deviceIntegrationDestinationListForInstallations: build.query<
      DeviceIntegrationDestinationListForInstallationsApiResponse,
      DeviceIntegrationDestinationListForInstallationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device/integration-destination/list-for-installations/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    devicesImportSettingsList: build.query<
      DevicesImportSettingsListApiResponse,
      DevicesImportSettingsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import-settings/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    devicesIntegrationList: build.query<
      DevicesIntegrationListApiResponse,
      DevicesIntegrationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-integration/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    devicesImportSettingsDuplicateList: build.query<
      DevicesImportSettingsDuplicateListApiResponse,
      DevicesImportSettingsDuplicateListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import-settings-duplicate/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    devicesImportSettingsInstallationsAllList: build.query<
      DevicesImportSettingsInstallationsAllListApiResponse,
      DevicesImportSettingsInstallationsAllListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import-settings-installations-all/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    devicesImportSettingsUpsert: build.mutation<
      DevicesImportSettingsUpsertApiResponse,
      DevicesImportSettingsUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import-settings/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
        method: "POST",
      }),
    }),
    devicesImportSettingsDelete: build.mutation<
      DevicesImportSettingsDeleteApiResponse,
      DevicesImportSettingsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import-settings/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        method: "POST",
      }),
    }),
    devicesImportList: build.query<
      DevicesImportListApiResponse,
      DevicesImportListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    devicesImportDestinationList: build.query<
      DevicesImportDestinationListApiResponse,
      DevicesImportDestinationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import/destination/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    devicesImportUpsert: build.mutation<
      DevicesImportUpsertApiResponse,
      DevicesImportUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_name/${encodeURIComponent(String(queryArg.sourceName))}/supplier_name/${encodeURIComponent(String(queryArg.supplierName))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/locality_name/${encodeURIComponent(String(queryArg.localityName))}/device_name/${encodeURIComponent(String(queryArg.deviceName))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}/unit_order_position/${encodeURIComponent(String(queryArg.unitOrderPosition))}/(gps_lat/${encodeURIComponent(String(queryArg.gpsLat))})/(gps_lng/${encodeURIComponent(String(queryArg.gpsLng))})/url_web/${encodeURIComponent(String(queryArg.urlWeb))}/url_data/${encodeURIComponent(String(queryArg.urlData))}/(rule_name/${encodeURIComponent(String(queryArg.ruleName))})/unit_unit/${encodeURIComponent(String(queryArg.unitUnit))}/unit_precision/${encodeURIComponent(String(queryArg.unitPrecision))}/unit_name/${encodeURIComponent(String(queryArg.unitName))}/unit_format/${encodeURIComponent(String(queryArg.unitFormat))}/unit_for_service/${encodeURIComponent(String(queryArg.unitForService))}`,
        method: "POST",
      }),
    }),
    devicesImportDelete: build.mutation<
      DevicesImportDeleteApiResponse,
      DevicesImportDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/id/${encodeURIComponent(String(queryArg.id))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        method: "POST",
      }),
    }),
    devicesImportDeviceImportedList: build.query<
      DevicesImportDeviceImportedListApiResponse,
      DevicesImportDeviceImportedListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/devices-import/device/imported/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/device_installation_id/${encodeURIComponent(String(queryArg.deviceInstallationId))}`,
      }),
    }),
    deviceImporterList: build.query<
      DeviceImporterListApiResponse,
      DeviceImporterListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device-importer/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_name/${encodeURIComponent(String(queryArg.sourceName))}/supplier_name/${encodeURIComponent(String(queryArg.supplierName))}`,
      }),
    }),
    deviceList: build.query<DeviceListApiResponse, DeviceListApiArg>({
      query: (queryArg) => ({
        url: `/api/device/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/locality_id/${encodeURIComponent(String(queryArg.localityId))}`,
      }),
    }),
    deviceSyncListInstallation: build.query<
      DeviceSyncListInstallationApiResponse,
      DeviceSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceUpsert: build.query<DeviceUpsertApiResponse, DeviceUpsertApiArg>({
      query: (queryArg) => ({
        url: `/api/device/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/locality_id/${encodeURIComponent(String(queryArg.localityId))}/name/${encodeURIComponent(String(queryArg.name))}/(description/${encodeURIComponent(String(queryArg.description))})/(gps_lat/${encodeURIComponent(String(queryArg.gpsLat))})/(gps_lng/${encodeURIComponent(String(queryArg.gpsLng))})/(order_position/${encodeURIComponent(String(queryArg.orderPosition))})`,
      }),
    }),
    deviceDelete: build.query<DeviceDeleteApiResponse, DeviceDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/device/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/locality_id/${encodeURIComponent(String(queryArg.localityId))}/name/${encodeURIComponent(String(queryArg.name))}`,
      }),
    }),
    deviceSyncInstallation: build.query<
      DeviceSyncInstallationApiResponse,
      DeviceSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.devices,
      }),
    }),
    deviceParentList: build.query<
      DeviceParentListApiResponse,
      DeviceParentListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device/parent/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceRecordList: build.query<
      DeviceRecordListApiResponse,
      DeviceRecordListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/time_from/${encodeURIComponent(String(queryArg.timeFrom))}/time_to/${encodeURIComponent(String(queryArg.timeTo))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceRecordSyncListDevice: build.query<
      DeviceRecordSyncListDeviceApiResponse,
      DeviceRecordSyncListDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record/sync/list/device/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/from_time/${encodeURIComponent(String(queryArg.fromTime))}/to_time/${encodeURIComponent(String(queryArg.toTime))}`,
      }),
    }),
    deviceRecordSyncDevice: build.query<
      DeviceRecordSyncDeviceApiResponse,
      DeviceRecordSyncDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record/sync/device/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        body: queryArg.deviceRecords,
      }),
    }),
    deviceRecordSyncUpsertDevice: build.query<
      DeviceRecordSyncUpsertDeviceApiResponse,
      DeviceRecordSyncUpsertDeviceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record/sync/upsert/device/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}/value/${encodeURIComponent(String(queryArg.value))}/time_utc/${encodeURIComponent(String(queryArg.timeUtc))}`,
      }),
    }),
    deviceRecordImportRecords: build.query<
      DeviceRecordImportRecordsApiResponse,
      DeviceRecordImportRecordsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record/import/records/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        body: queryArg.deviceRecords,
      }),
    }),
    deviceRecordImportRecordsByHierarchy: build.query<
      DeviceRecordImportRecordsByHierarchyApiResponse,
      DeviceRecordImportRecordsByHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record/import/records-by-hierarchy/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/source_name/${encodeURIComponent(String(queryArg.sourceName))}/supplier_name/${encodeURIComponent(String(queryArg.supplierName))}/device_name/${encodeURIComponent(String(queryArg.deviceName))}`,
        body: queryArg.deviceRecords,
      }),
    }),
    deviceRecordLastList: build.query<
      DeviceRecordLastListApiResponse,
      DeviceRecordLastListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_record_last/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceTagsList: build.query<
      DeviceTagsListApiResponse,
      DeviceTagsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_tags/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceTagsSyncListInstallation: build.query<
      DeviceTagsSyncListInstallationApiResponse,
      DeviceTagsSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_tags/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceTagsUpsert: build.query<
      DeviceTagsUpsertApiResponse,
      DeviceTagsUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_tags/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}/(tag_value/${encodeURIComponent(String(queryArg.tagValue))})`,
      }),
    }),
    deviceTagsDelete: build.query<
      DeviceTagsDeleteApiResponse,
      DeviceTagsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_tags/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}`,
      }),
    }),
    deviceTagsSyncInstallation: build.query<
      DeviceTagsSyncInstallationApiResponse,
      DeviceTagsSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_tags/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.deviceTags,
      }),
    }),
    deviceUnitLevelRuleItemList: build.query<
      DeviceUnitLevelRuleItemListApiResponse,
      DeviceUnitLevelRuleItemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule-item/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceUnitLevelRuleList: build.query<
      DeviceUnitLevelRuleListApiResponse,
      DeviceUnitLevelRuleListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceUnitLevelRuleItemListInstallation: build.query<
      DeviceUnitLevelRuleItemListInstallationApiResponse,
      DeviceUnitLevelRuleItemListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule-item/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceUnitLevelRuleUpsert: build.query<
      DeviceUnitLevelRuleUpsertApiResponse,
      DeviceUnitLevelRuleUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_id/${encodeURIComponent(String(queryArg.originInstallationId))}/device_unit_id/${encodeURIComponent(String(queryArg.deviceUnitId))}/rule_name/${encodeURIComponent(String(queryArg.ruleName))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceUnitLevelRuleItemUpsert: build.query<
      DeviceUnitLevelRuleItemUpsertApiResponse,
      DeviceUnitLevelRuleItemUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule-item/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceUnitLevelRuleDelete: build.query<
      DeviceUnitLevelRuleDeleteApiResponse,
      DeviceUnitLevelRuleDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}`,
      }),
    }),
    deviceUnitLevelRuleItemSynchronize: build.mutation<
      DeviceUnitLevelRuleItemSynchronizeApiResponse,
      DeviceUnitLevelRuleItemSynchronizeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule-item/synchronize/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}`,
        method: "POST",
        body: queryArg.deviceUnitLevelRuleItems,
      }),
    }),
    deviceUnitLevelRuleGetIds: build.query<
      DeviceUnitLevelRuleGetIdsApiResponse,
      DeviceUnitLevelRuleGetIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit-level-rule/get-ids/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceUnitList: build.query<
      DeviceUnitListApiResponse,
      DeviceUnitListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}`,
      }),
    }),
    deviceUnitSyncListInstallation: build.query<
      DeviceUnitSyncListInstallationApiResponse,
      DeviceUnitSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceUnitListInstallation: build.query<
      DeviceUnitListInstallationApiResponse,
      DeviceUnitListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    deviceUnitUpsert: build.query<
      DeviceUnitUpsertApiResponse,
      DeviceUnitUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/locality_id/${encodeURIComponent(String(queryArg.localityId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}`,
      }),
    }),
    deviceUnitDelete: build.query<
      DeviceUnitDeleteApiResponse,
      DeviceUnitDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/device_id/${encodeURIComponent(String(queryArg.deviceId))}/unit_type/${encodeURIComponent(String(queryArg.unitType))}`,
      }),
    }),
    deviceUnitSyncInstallation: build.query<
      DeviceUnitSyncInstallationApiResponse,
      DeviceUnitSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.deviceUnits,
      }),
    }),
    deviceUnitSyncGetIds: build.query<
      DeviceUnitSyncGetIdsApiResponse,
      DeviceUnitSyncGetIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/device_unit/sync/get-ids/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    installationCommandRequestDestinationVolume: build.mutation<
      InstallationCommandRequestDestinationVolumeApiResponse,
      InstallationCommandRequestDestinationVolumeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/command/request-destination-volume/command/${encodeURIComponent(String(queryArg.command))}/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        method: "PUT",
      }),
    }),
    installationCommandReply: build.mutation<
      InstallationCommandReplyApiResponse,
      InstallationCommandReplyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/command/reply/command/${encodeURIComponent(String(queryArg.command))}/command_parameter/${encodeURIComponent(String(queryArg.commandParameter))}/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/request_token/${encodeURIComponent(String(queryArg.requestToken))}/origin_server/${encodeURIComponent(String(queryArg.originServer))}/result_ok/${encodeURIComponent(String(queryArg.resultOk))}/result_message/${encodeURIComponent(String(queryArg.resultMessage))}`,
        method: "PUT",
      }),
    }),
    installationCommandSyncRequest: build.mutation<
      InstallationCommandSyncRequestApiResponse,
      InstallationCommandSyncRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/command/sync/request/command/${encodeURIComponent(String(queryArg.command))}/command_parameter/${encodeURIComponent(String(queryArg.commandParameter))}/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/request_token/${encodeURIComponent(String(queryArg.requestToken))}/origin_server/${encodeURIComponent(String(queryArg.originServer))}/user_name/${encodeURIComponent(String(queryArg.userName))}`,
        method: "PUT",
      }),
    }),
    installationLocalUpsertAndReplicate: build.query<
      InstallationLocalUpsertAndReplicateApiResponse,
      InstallationLocalUpsertAndReplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation-local/upsert-and-replicate/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_name/${encodeURIComponent(String(queryArg.installationName))}`,
      }),
    }),
    installationLocalListAll: build.query<
      InstallationLocalListAllApiResponse,
      InstallationLocalListAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation-local/list-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    installationsGuess: build.query<
      InstallationsGuessApiResponse,
      InstallationsGuessApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installations-guess/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    installationListAttachSystem: build.query<
      InstallationListAttachSystemApiResponse,
      InstallationListAttachSystemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/list-attach-system/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    installationList: build.query<
      InstallationListApiResponse,
      InstallationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    installationsGuessUserOnly: build.query<
      InstallationsGuessUserOnlyApiResponse,
      InstallationsGuessUserOnlyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installations-guess-user-only/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    installationUpsert: build.query<
      InstallationUpsertApiResponse,
      InstallationUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_name/${encodeURIComponent(String(queryArg.installationName))}`,
      }),
    }),
    installationDelete: build.query<
      InstallationDeleteApiResponse,
      InstallationDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    installationGetByName: build.query<
      InstallationGetByNameApiResponse,
      InstallationGetByNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/get-by-name/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_name/${encodeURIComponent(String(queryArg.installationName))}`,
      }),
    }),
    installationImportedDeviceList: build.query<
      InstallationImportedDeviceListApiResponse,
      InstallationImportedDeviceListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/imported/device/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    installationSyncImportedDeviceInst: build.query<
      InstallationSyncImportedDeviceInstApiResponse,
      InstallationSyncImportedDeviceInstApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation/sync/imported/device/inst/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.installationImporteds,
      }),
    }),
    installationTagsListSectionNoKeys: build.query<
      InstallationTagsListSectionNoKeysApiResponse,
      InstallationTagsListSectionNoKeysApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/list-section-no-keys/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/config/${encodeURIComponent(String(queryArg.config))}/section/${encodeURIComponent(String(queryArg.section))}`,
      }),
    }),
    installationTagsListTextToSpeech: build.query<
      InstallationTagsListTextToSpeechApiResponse,
      InstallationTagsListTextToSpeechApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/list-text-to-speech/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    installationTagsUpsertTextToSpeech: build.mutation<
      InstallationTagsUpsertTextToSpeechApiResponse,
      InstallationTagsUpsertTextToSpeechApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/upsert-text-to-speech/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}/(tag_value/${encodeURIComponent(String(queryArg.tagValue))})`,
        method: "POST",
      }),
    }),
    installationTagsUpsertLicencing: build.mutation<
      InstallationTagsUpsertLicencingApiResponse,
      InstallationTagsUpsertLicencingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/upsert-licencing/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}/(tag_value/${encodeURIComponent(String(queryArg.tagValue))})`,
        method: "POST",
      }),
    }),
    installationTagsSyncSection: build.mutation<
      InstallationTagsSyncSectionApiResponse,
      InstallationTagsSyncSectionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/sync/section/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/config/${encodeURIComponent(String(queryArg.config))}/section/${encodeURIComponent(String(queryArg.section))}`,
        method: "POST",
        body: queryArg.installationTagSectionItemNews,
      }),
    }),
    installationTagsSyncList: build.query<
      InstallationTagsSyncListApiResponse,
      InstallationTagsSyncListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/sync/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    installationTagsSyncInstallationOrigin: build.mutation<
      InstallationTagsSyncInstallationOriginApiResponse,
      InstallationTagsSyncInstallationOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installation_tags/sync/installation-origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/state_uid_remote/${encodeURIComponent(String(queryArg.stateUidRemote))}`,
        method: "POST",
        body: queryArg.installationTags,
      }),
    }),
    installatorRegisterLocalInstallation: build.query<
      InstallatorRegisterLocalInstallationApiResponse,
      InstallatorRegisterLocalInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installator/register-local/installation/create_installation_name/${encodeURIComponent(String(queryArg.createInstallationName))}`,
      }),
    }),
    installatorRegisterInstallation: build.mutation<
      InstallatorRegisterInstallationApiResponse,
      InstallatorRegisterInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/installator/register/installation/login_installation_name/${encodeURIComponent(String(queryArg.loginInstallationName))}/login_username/${encodeURIComponent(String(queryArg.loginUsername))}/create_installation_name/${encodeURIComponent(String(queryArg.createInstallationName))}`,
        method: "POST",
        body: queryArg.userLoginPassword,
      }),
    }),
    localityList: build.query<LocalityListApiResponse, LocalityListApiArg>({
      query: (queryArg) => ({
        url: `/api/locality/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}`,
      }),
    }),
    localityListInstallations: build.query<
      LocalityListInstallationsApiResponse,
      LocalityListInstallationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locality/list-installations/session_id/${encodeURIComponent(String(queryArg.sessionId))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    localitySyncListInstallation: build.query<
      LocalitySyncListInstallationApiResponse,
      LocalitySyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locality/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    localityUpsert: build.query<
      LocalityUpsertApiResponse,
      LocalityUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locality/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}/(address/${encodeURIComponent(String(queryArg.address))})/name/${encodeURIComponent(String(queryArg.name))}/(description/${encodeURIComponent(String(queryArg.description))})/(kind/${encodeURIComponent(String(queryArg.kind))})/(order_position/${encodeURIComponent(String(queryArg.orderPosition))})`,
      }),
    }),
    localityDelete: build.query<
      LocalityDeleteApiResponse,
      LocalityDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locality/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}/name/${encodeURIComponent(String(queryArg.name))}`,
      }),
    }),
    localitySyncInstallation: build.query<
      LocalitySyncInstallationApiResponse,
      LocalitySyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/locality/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.localitys,
      }),
    }),
    mqttInternalBridgeAuth: build.query<
      MqttInternalBridgeAuthApiResponse,
      MqttInternalBridgeAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/mqtt-internal/bridge/auth/installation_name/${encodeURIComponent(String(queryArg.installationName))}/login/${encodeURIComponent(String(queryArg.login))}/password/${encodeURIComponent(String(queryArg.password))}`,
      }),
    }),
    mqttWebsocketGenerateOneTimeSessionPwd: build.query<
      MqttWebsocketGenerateOneTimeSessionPwdApiResponse,
      MqttWebsocketGenerateOneTimeSessionPwdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/mqtt/websocket/generate-one-time-session-pwd/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    mqttInternalWebsocketAuth: build.query<
      MqttInternalWebsocketAuthApiResponse,
      MqttInternalWebsocketAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/mqtt-internal/websocket/auth/session_id/${encodeURIComponent(String(queryArg.sessionId))}/password/${encodeURIComponent(String(queryArg.password))}`,
      }),
    }),
    mqttInternalWebsocketTopicList: build.query<
      MqttInternalWebsocketTopicListApiResponse,
      MqttInternalWebsocketTopicListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/mqtt-internal/websocket/topic/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    sessionNotificationMqttTopicList: build.query<
      SessionNotificationMqttTopicListApiResponse,
      SessionNotificationMqttTopicListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/session/notification/mqtt/topic/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    sessionNotificationMqttTopicListAll: build.query<
      SessionNotificationMqttTopicListAllApiResponse,
      SessionNotificationMqttTopicListAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/session/notification/mqtt/topic/list-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    permissionList: build.query<
      PermissionListApiResponse,
      PermissionListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
      }),
    }),
    permissionUpsert: build.mutation<
      PermissionUpsertApiResponse,
      PermissionUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}/code/${encodeURIComponent(String(queryArg.code))}/for_installation_id/${encodeURIComponent(String(queryArg.forInstallationId))}`,
        method: "PUT",
      }),
    }),
    permissionSetAll: build.mutation<
      PermissionSetAllApiResponse,
      PermissionSetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/set-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
        method: "PUT",
        body: queryArg.sidPermissions,
      }),
    }),
    permissionInternalList: build.query<
      PermissionInternalListApiResponse,
      PermissionInternalListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/internal/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
      }),
    }),
    permissionSystemList: build.query<
      PermissionSystemListApiResponse,
      PermissionSystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/system/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
      }),
    }),
    permissionSystemSetAll: build.mutation<
      PermissionSystemSetAllApiResponse,
      PermissionSystemSetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/system/set-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
        method: "PUT",
        body: queryArg.sidPermissions,
      }),
    }),
    permissionUserList: build.query<
      PermissionUserListApiResponse,
      PermissionUserListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission/user/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
      }),
    }),
    permissionGroupList: build.query<
      PermissionGroupListApiResponse,
      PermissionGroupListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    permissionGroupCreate: build.mutation<
      PermissionGroupCreateApiResponse,
      PermissionGroupCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}`,
        method: "POST",
      }),
    }),
    permissionGroupUpdate: build.mutation<
      PermissionGroupUpdateApiResponse,
      PermissionGroupUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/update/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/name/${encodeURIComponent(String(queryArg.name))}`,
        method: "PUT",
      }),
    }),
    permissionGroupDelete: build.mutation<
      PermissionGroupDeleteApiResponse,
      PermissionGroupDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "DELETE",
      }),
    }),
    permissionGroupInternalList: build.query<
      PermissionGroupInternalListApiResponse,
      PermissionGroupInternalListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/internal/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    permissionGroupSystemList: build.query<
      PermissionGroupSystemListApiResponse,
      PermissionGroupSystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/system/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    permissionGroupSystemCreate: build.mutation<
      PermissionGroupSystemCreateApiResponse,
      PermissionGroupSystemCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/system/create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}`,
        method: "POST",
      }),
    }),
    permissionGroupSystemUpdate: build.mutation<
      PermissionGroupSystemUpdateApiResponse,
      PermissionGroupSystemUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/system/update/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/name/${encodeURIComponent(String(queryArg.name))}`,
        method: "PUT",
      }),
    }),
    permissionGroupSystemDelete: build.mutation<
      PermissionGroupSystemDeleteApiResponse,
      PermissionGroupSystemDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-group/system/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "DELETE",
      }),
    }),
    permissionLocalityList: build.query<
      PermissionLocalityListApiResponse,
      PermissionLocalityListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-locality/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    permissionLocalitySetAll: build.mutation<
      PermissionLocalitySetAllApiResponse,
      PermissionLocalitySetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-locality/set-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
        method: "PUT",
        body: queryArg.permissionLocalityDests,
      }),
    }),
    permissionLocalityListUser: build.query<
      PermissionLocalityListUserApiResponse,
      PermissionLocalityListUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-locality/list-user/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    permissionLocalitySystemList: build.query<
      PermissionLocalitySystemListApiResponse,
      PermissionLocalitySystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-locality/system/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    permissionLocalitySystemSetAll: build.mutation<
      PermissionLocalitySystemSetAllApiResponse,
      PermissionLocalitySystemSetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-locality/system/set-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
        method: "PUT",
        body: queryArg.permissionLocalityDests,
      }),
    }),
    permissionLocalitySystemListUser: build.query<
      PermissionLocalitySystemListUserApiResponse,
      PermissionLocalitySystemListUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-locality/system/list-user/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/for_installation_ids/${encodeURIComponent(String(queryArg.forInstallationIds))}`,
      }),
    }),
    permissionTreeListUser: build.query<
      PermissionTreeListUserApiResponse,
      PermissionTreeListUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/list-user/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_user/${encodeURIComponent(String(queryArg.sidUser))}`,
      }),
    }),
    permissionTreeListGroup: build.query<
      PermissionTreeListGroupApiResponse,
      PermissionTreeListGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/list-group/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
      }),
    }),
    permissionTreeSetUserAll: build.mutation<
      PermissionTreeSetUserAllApiResponse,
      PermissionTreeSetUserAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/set-user-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_user/${encodeURIComponent(String(queryArg.sidUser))}`,
        method: "PUT",
        body: queryArg.permissionGroupKeys,
      }),
    }),
    permissionTreeSetGroupAll: build.mutation<
      PermissionTreeSetGroupAllApiResponse,
      PermissionTreeSetGroupAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/set-group-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
        method: "PUT",
        body: queryArg.permissionGroupKeys,
      }),
    }),
    permissionTreeInternalListUser: build.query<
      PermissionTreeInternalListUserApiResponse,
      PermissionTreeInternalListUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/internal/list-user/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_user/${encodeURIComponent(String(queryArg.sidUser))}`,
      }),
    }),
    permissionTreeInternalListGroup: build.query<
      PermissionTreeInternalListGroupApiResponse,
      PermissionTreeInternalListGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/internal/list-group/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
      }),
    }),
    permissionTreeSystemListUser: build.query<
      PermissionTreeSystemListUserApiResponse,
      PermissionTreeSystemListUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/system/list-user/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_user/${encodeURIComponent(String(queryArg.sidUser))}`,
      }),
    }),
    permissionTreeSystemListGroup: build.query<
      PermissionTreeSystemListGroupApiResponse,
      PermissionTreeSystemListGroupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/system/list-group/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
      }),
    }),
    permissionTreeSystemSetUserAll: build.mutation<
      PermissionTreeSystemSetUserAllApiResponse,
      PermissionTreeSystemSetUserAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/system/set-user-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_user/${encodeURIComponent(String(queryArg.sidUser))}`,
        method: "PUT",
        body: queryArg.permissionGroupKeys,
      }),
    }),
    permissionTreeSystemSetGroupAll: build.mutation<
      PermissionTreeSystemSetGroupAllApiResponse,
      PermissionTreeSystemSetGroupAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/permission-tree/system/set-group-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid_group/${encodeURIComponent(String(queryArg.sidGroup))}`,
        method: "PUT",
        body: queryArg.permissionGroupKeys,
      }),
    }),
    personGetByLogin: build.query<
      PersonGetByLoginApiResponse,
      PersonGetByLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/get/by-login/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/login/${encodeURIComponent(String(queryArg.login))}`,
      }),
    }),
    personList: build.query<PersonListApiResponse, PersonListApiArg>({
      query: (queryArg) => ({
        url: `/api/person/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    personCreate: build.mutation<PersonCreateApiResponse, PersonCreateApiArg>({
      query: (queryArg) => ({
        url: `/api/person/create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/firstname/${encodeURIComponent(String(queryArg.firstname))}/surname/${encodeURIComponent(String(queryArg.surname))}/description/${encodeURIComponent(String(queryArg.description))}`,
        method: "POST",
      }),
    }),
    personUpdate: build.mutation<PersonUpdateApiResponse, PersonUpdateApiArg>({
      query: (queryArg) => ({
        url: `/api/person/update/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/firstname/${encodeURIComponent(String(queryArg.firstname))}/surname/${encodeURIComponent(String(queryArg.surname))}/description/${encodeURIComponent(String(queryArg.description))}`,
        method: "PUT",
      }),
    }),
    personDelete: build.mutation<PersonDeleteApiResponse, PersonDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/person/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "DELETE",
      }),
    }),
    personCredentialsGet: build.query<
      PersonCredentialsGetApiResponse,
      PersonCredentialsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/credentials/get/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
      }),
    }),
    personCredentialsGetSids: build.query<
      PersonCredentialsGetSidsApiResponse,
      PersonCredentialsGetSidsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/credentials/get-sids/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sids/${encodeURIComponent(String(queryArg.sids))}`,
      }),
    }),
    personCredentialsUpsertWithoutPassword: build.mutation<
      PersonCredentialsUpsertWithoutPasswordApiResponse,
      PersonCredentialsUpsertWithoutPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/credentials/upsert-without-password/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/login/${encodeURIComponent(String(queryArg.login))}/login_enabled/${encodeURIComponent(String(queryArg.loginEnabled))}/password_set_enabled/${encodeURIComponent(String(queryArg.passwordSetEnabled))}/password_set_required/${encodeURIComponent(String(queryArg.passwordSetRequired))}`,
        method: "PUT",
      }),
    }),
    personCredentialsUpdatePassword: build.mutation<
      PersonCredentialsUpdatePasswordApiResponse,
      PersonCredentialsUpdatePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/credentials/update-password/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "PUT",
        body: queryArg.userLoginPassword,
      }),
    }),
    personCredentialsSelfupdatePassword: build.mutation<
      PersonCredentialsSelfupdatePasswordApiResponse,
      PersonCredentialsSelfupdatePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/credentials/selfupdate-password/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        method: "PUT",
        body: queryArg.userLoginChangePassword,
      }),
    }),
    personTokenUpsert: build.mutation<
      PersonTokenUpsertApiResponse,
      PersonTokenUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/token/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "PUT",
        body: queryArg.userLoginToken,
      }),
    }),
    personCredentialsDelete: build.mutation<
      PersonCredentialsDeleteApiResponse,
      PersonCredentialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/credentials/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "DELETE",
      }),
    }),
    personInternalList: build.query<
      PersonInternalListApiResponse,
      PersonInternalListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/internal/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    personSystemGetByLogin: build.query<
      PersonSystemGetByLoginApiResponse,
      PersonSystemGetByLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/get/by-login/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/login/${encodeURIComponent(String(queryArg.login))}`,
      }),
    }),
    personSystemList: build.query<
      PersonSystemListApiResponse,
      PersonSystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    personSystemCreate: build.mutation<
      PersonSystemCreateApiResponse,
      PersonSystemCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/firstname/${encodeURIComponent(String(queryArg.firstname))}/surname/${encodeURIComponent(String(queryArg.surname))}/description/${encodeURIComponent(String(queryArg.description))}`,
        method: "POST",
      }),
    }),
    personSystemUpdate: build.mutation<
      PersonSystemUpdateApiResponse,
      PersonSystemUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/update/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/firstname/${encodeURIComponent(String(queryArg.firstname))}/surname/${encodeURIComponent(String(queryArg.surname))}/description/${encodeURIComponent(String(queryArg.description))}`,
        method: "PUT",
      }),
    }),
    personSystemDelete: build.mutation<
      PersonSystemDeleteApiResponse,
      PersonSystemDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "DELETE",
      }),
    }),
    personSystemCredentialsGet: build.query<
      PersonSystemCredentialsGetApiResponse,
      PersonSystemCredentialsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/credentials/get/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
      }),
    }),
    personSystemCredentialsGetSids: build.query<
      PersonSystemCredentialsGetSidsApiResponse,
      PersonSystemCredentialsGetSidsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/credentials/get-sids/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sids/${encodeURIComponent(String(queryArg.sids))}`,
      }),
    }),
    personSystemCredentialsUpsertWithoutPassword: build.mutation<
      PersonSystemCredentialsUpsertWithoutPasswordApiResponse,
      PersonSystemCredentialsUpsertWithoutPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/credentials/upsert-without-password/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/login/${encodeURIComponent(String(queryArg.login))}/login_enabled/${encodeURIComponent(String(queryArg.loginEnabled))}/password_set_enabled/${encodeURIComponent(String(queryArg.passwordSetEnabled))}/password_set_required/${encodeURIComponent(String(queryArg.passwordSetRequired))}`,
        method: "PUT",
      }),
    }),
    personSystemCredentialsUpdatePassword: build.mutation<
      PersonSystemCredentialsUpdatePasswordApiResponse,
      PersonSystemCredentialsUpdatePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/credentials/update-password/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "PUT",
        body: queryArg.userLoginPassword,
      }),
    }),
    personSystemCredentialsUpdateWithoutPassword: build.mutation<
      PersonSystemCredentialsUpdateWithoutPasswordApiResponse,
      PersonSystemCredentialsUpdateWithoutPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/credentials/update-without-password/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/login/${encodeURIComponent(String(queryArg.login))}`,
        method: "PUT",
      }),
    }),
    personSystemTokenUpsert: build.mutation<
      PersonSystemTokenUpsertApiResponse,
      PersonSystemTokenUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/token/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "PUT",
        body: queryArg.userLoginToken,
      }),
    }),
    personSystemCredentialsDelete: build.mutation<
      PersonSystemCredentialsDeleteApiResponse,
      PersonSystemCredentialsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person/system/credentials/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
        method: "DELETE",
      }),
    }),
    personTagList: build.query<PersonTagListApiResponse, PersonTagListApiArg>({
      query: (queryArg) => ({
        url: `/api/person_tag/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
      }),
    }),
    personTagUpsert: build.mutation<
      PersonTagUpsertApiResponse,
      PersonTagUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person_tag/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}/tag_value/${encodeURIComponent(String(queryArg.tagValue))}`,
        method: "POST",
      }),
    }),
    personTagInternalList: build.query<
      PersonTagInternalListApiResponse,
      PersonTagInternalListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person_tag/internal/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
      }),
    }),
    personTagSystemList: build.query<
      PersonTagSystemListApiResponse,
      PersonTagSystemListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person_tag/system/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}`,
      }),
    }),
    personTagSystemUpsert: build.mutation<
      PersonTagSystemUpsertApiResponse,
      PersonTagSystemUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/person_tag/system/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/sid/${encodeURIComponent(String(queryArg.sid))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}/tag_value/${encodeURIComponent(String(queryArg.tagValue))}`,
        method: "POST",
      }),
    }),
    relationDestinationList: build.query<
      RelationDestinationListApiResponse,
      RelationDestinationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
      }),
    }),
    relationDestinationListInstallation: build.query<
      RelationDestinationListInstallationApiResponse,
      RelationDestinationListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    relationDestinationCreateForInstallationAll: build.mutation<
      RelationDestinationCreateForInstallationAllApiResponse,
      RelationDestinationCreateForInstallationAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/create/for_installation/all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/for_installation_id/${encodeURIComponent(String(queryArg.forInstallationId))}`,
        method: "POST",
      }),
    }),
    relationDestinationDeleteForInstallation: build.mutation<
      RelationDestinationDeleteForInstallationApiResponse,
      RelationDestinationDeleteForInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/delete/for_installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/for_installation_id/${encodeURIComponent(String(queryArg.forInstallationId))}/address/${encodeURIComponent(String(queryArg.address))}`,
        method: "DELETE",
      }),
    }),
    relationDestinationCreateAll: build.mutation<
      RelationDestinationCreateAllApiResponse,
      RelationDestinationCreateAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/create/all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
        method: "POST",
      }),
    }),
    relationDestinationDelete: build.mutation<
      RelationDestinationDeleteApiResponse,
      RelationDestinationDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/address/${encodeURIComponent(String(queryArg.address))}`,
        method: "DELETE",
      }),
    }),
    relationDestinationSetAllDestinations: build.mutation<
      RelationDestinationSetAllDestinationsApiResponse,
      RelationDestinationSetAllDestinationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/set-all-destinations/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
        method: "POST",
        body: queryArg.relationDestinationsJsons,
      }),
    }),
    relationDestinationSyncOrigin: build.query<
      RelationDestinationSyncOriginApiResponse,
      RelationDestinationSyncOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/sync/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
        body: queryArg.relationDestinations,
      }),
    }),
    relationDestinationSyncListOrigin: build.query<
      RelationDestinationSyncListOriginApiResponse,
      RelationDestinationSyncListOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/destination/sync/list/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    relationFileList: build.query<
      RelationFileListApiResponse,
      RelationFileListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
      }),
    }),
    relationFileCreateUserFile: build.mutation<
      RelationFileCreateUserFileApiResponse,
      RelationFileCreateUserFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/create/user-file/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/file_name/${encodeURIComponent(String(queryArg.fileName))}/order_position/${encodeURIComponent(String(queryArg.orderPosition))}/is_public/${encodeURIComponent(String(queryArg.isPublic))}/file_size/${encodeURIComponent(String(queryArg.fileSize))}/file_utc/${encodeURIComponent(String(queryArg.fileUtc))}`,
        method: "POST",
      }),
    }),
    relationFileAddFromSystemSet: build.mutation<
      RelationFileAddFromSystemSetApiResponse,
      RelationFileAddFromSystemSetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/add/from-system-set/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/type/${encodeURIComponent(String(queryArg["type"]))}/order_position/${encodeURIComponent(String(queryArg.orderPosition))}/is_public/${encodeURIComponent(String(queryArg.isPublic))}/file_utc/${encodeURIComponent(String(queryArg.fileUtc))}`,
        method: "POST",
      }),
    }),
    relationFileUpload: build.mutation<
      RelationFileUploadApiResponse,
      RelationFileUploadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/upload/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/file_id/${encodeURIComponent(String(queryArg.fileId))}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    relationFileDelete: build.mutation<
      RelationFileDeleteApiResponse,
      RelationFileDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/file_id/${encodeURIComponent(String(queryArg.fileId))}`,
        method: "DELETE",
      }),
    }),
    relationFileSetOrderAllFiles: build.mutation<
      RelationFileSetOrderAllFilesApiResponse,
      RelationFileSetOrderAllFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/set-order-all-files/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
        method: "PUT",
        body: queryArg.filePositionJsons,
      }),
    }),
    relationFileDownload: build.query<
      RelationFileDownloadApiResponse,
      RelationFileDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/download/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/file_id/${encodeURIComponent(String(queryArg.fileId))}`,
      }),
    }),
    relationFileSingleFileCopy: build.mutation<
      RelationFileSingleFileCopyApiResponse,
      RelationFileSingleFileCopyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/single_file_copy/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/file_id/${encodeURIComponent(String(queryArg.fileId))}/order_position/${encodeURIComponent(String(queryArg.orderPosition))}`,
        method: "POST",
      }),
    }),
    relationFileSyncFromSystemSet: build.mutation<
      RelationFileSyncFromSystemSetApiResponse,
      RelationFileSyncFromSystemSetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/sync/from-system-set/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/filename/${encodeURIComponent(String(queryArg.filename))}/(duration_seconds/${encodeURIComponent(String(queryArg.durationSeconds))})/order_position/${encodeURIComponent(String(queryArg.orderPosition))}/is_public/${encodeURIComponent(String(queryArg.isPublic))}/file_utc/${encodeURIComponent(String(queryArg.fileUtc))}/state_uid_content/${encodeURIComponent(String(queryArg.stateUidContent))}`,
        method: "POST",
      }),
    }),
    relationFileSyncOrigin: build.query<
      RelationFileSyncOriginApiResponse,
      RelationFileSyncOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/sync/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
        body: queryArg.relationFiles,
      }),
    }),
    relationFileSyncListOrigin: build.query<
      RelationFileSyncListOriginApiResponse,
      RelationFileSyncListOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/sync/list/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    relationFileTextList: build.query<
      RelationFileTextListApiResponse,
      RelationFileTextListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/text/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/id/${encodeURIComponent(String(queryArg.id))}`,
      }),
    }),
    relationFileSetUserFileText: build.mutation<
      RelationFileSetUserFileTextApiResponse,
      RelationFileSetUserFileTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/set/user-file-text/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/id/${encodeURIComponent(String(queryArg.id))}/(speech_text/${encodeURIComponent(String(queryArg.speechText))})`,
        method: "POST",
      }),
    }),
    relationFileSetFileIsPublic: build.mutation<
      RelationFileSetFileIsPublicApiResponse,
      RelationFileSetFileIsPublicApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/set/file-is-public/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/id/${encodeURIComponent(String(queryArg.id))}/is_public/${encodeURIComponent(String(queryArg.isPublic))}`,
        method: "POST",
      }),
    }),
    relationFileSyncStateUidText: build.mutation<
      RelationFileSyncStateUidTextApiResponse,
      RelationFileSyncStateUidTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/file/sync/state-uid-text/session_id/${encodeURIComponent(String(queryArg.sessionId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/state_uid_content/${encodeURIComponent(String(queryArg.stateUidContent))}/(state_uid_text/${encodeURIComponent(String(queryArg.stateUidText))})`,
        method: "POST",
      }),
    }),
    relationPlanDestinationList: build.query<
      RelationPlanDestinationListApiResponse,
      RelationPlanDestinationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/destination/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}`,
      }),
    }),
    relationPlanDestinationListInstallation: build.query<
      RelationPlanDestinationListInstallationApiResponse,
      RelationPlanDestinationListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/destination/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    relationPlanDestinationSetAll: build.mutation<
      RelationPlanDestinationSetAllApiResponse,
      RelationPlanDestinationSetAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/destination/set-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}`,
        method: "POST",
        body: queryArg.planDestinationsJsons,
      }),
    }),
    relationPlanDestinationSyncOrigin: build.query<
      RelationPlanDestinationSyncOriginApiResponse,
      RelationPlanDestinationSyncOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/destination/sync/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
        body: queryArg.planDestinations,
      }),
    }),
    relationPlanDestinationSyncListOrigin: build.query<
      RelationPlanDestinationSyncListOriginApiResponse,
      RelationPlanDestinationSyncListOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/destination/sync/list/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    relationPlanPublic: build.query<
      RelationPlanPublicApiResponse,
      RelationPlanPublicApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/public/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    relationPlanStateListInstallation: build.query<
      RelationPlanStateListInstallationApiResponse,
      RelationPlanStateListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/state/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    relationPlanStateSyncUpsert: build.query<
      RelationPlanStateSyncUpsertApiResponse,
      RelationPlanStateSyncUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/state/sync/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}/state_installation_id/${encodeURIComponent(String(queryArg.stateInstallationId))}/state_code/${encodeURIComponent(String(queryArg.stateCode))}/state_text/${encodeURIComponent(String(queryArg.stateText))}/state_utc/${encodeURIComponent(String(queryArg.stateUtc))}/(is_state_canceled/${encodeURIComponent(String(queryArg.isStateCanceled))})/(is_state_downloading/${encodeURIComponent(String(queryArg.isStateDownloading))})/(is_state_ready/${encodeURIComponent(String(queryArg.isStateReady))})/(is_state_playing/${encodeURIComponent(String(queryArg.isStatePlaying))})/(is_state_error/${encodeURIComponent(String(queryArg.isStateError))})/(is_state_done/${encodeURIComponent(String(queryArg.isStateDone))})`,
      }),
    }),
    relationPlanStateSyncDelete: build.query<
      RelationPlanStateSyncDeleteApiResponse,
      RelationPlanStateSyncDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/state/sync/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/state_installation_id/${encodeURIComponent(String(queryArg.stateInstallationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}/state_utc/${encodeURIComponent(String(queryArg.stateUtc))}`,
      }),
    }),
    relationPlanStateSyncStateInstallation: build.query<
      RelationPlanStateSyncStateInstallationApiResponse,
      RelationPlanStateSyncStateInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/state/sync/state_installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/state_installation_id/${encodeURIComponent(String(queryArg.stateInstallationId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
        body: queryArg.relationPlanStates,
      }),
    }),
    relationPlanStateSyncListOrigin: build.query<
      RelationPlanStateSyncListOriginApiResponse,
      RelationPlanStateSyncListOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/state/sync/list/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    relationPlanListInstallation: build.query<
      RelationPlanListInstallationApiResponse,
      RelationPlanListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    relationPlanList: build.query<
      RelationPlanListApiResponse,
      RelationPlanListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
      }),
    }),
    relationPlanCreate: build.mutation<
      RelationPlanCreateApiResponse,
      RelationPlanCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/name/${encodeURIComponent(String(queryArg.name))}/start_utc/${encodeURIComponent(String(queryArg.startUtc))}/max_delay_in_seconds/${encodeURIComponent(String(queryArg.maxDelayInSeconds))}`,
        method: "POST",
      }),
    }),
    relationPlanUpdate: build.mutation<
      RelationPlanUpdateApiResponse,
      RelationPlanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/update/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}/start_utc/${encodeURIComponent(String(queryArg.startUtc))}/max_delay_in_seconds/${encodeURIComponent(String(queryArg.maxDelayInSeconds))}`,
        method: "PUT",
      }),
    }),
    relationPlanDelete: build.mutation<
      RelationPlanDeleteApiResponse,
      RelationPlanDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}`,
        method: "DELETE",
      }),
    }),
    relationPlanSetComplete: build.mutation<
      RelationPlanSetCompleteApiResponse,
      RelationPlanSetCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/set-complete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}/is_complete/${encodeURIComponent(String(queryArg.isComplete))}`,
        method: "PUT",
      }),
    }),
    relationPlanCompletationInfo: build.query<
      RelationPlanCompletationInfoApiResponse,
      RelationPlanCompletationInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/completation-info/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}`,
      }),
    }),
    relationPlanSyncOrigin: build.query<
      RelationPlanSyncOriginApiResponse,
      RelationPlanSyncOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/sync/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
        body: queryArg.relationPlans,
      }),
    }),
    relationPlanSyncListOrigin: build.query<
      RelationPlanSyncListOriginApiResponse,
      RelationPlanSyncListOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/sync/list/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    relationPlanSyncStateUpdateDest: build.query<
      RelationPlanSyncStateUpdateDestApiResponse,
      RelationPlanSyncStateUpdateDestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/sync/state-update-dest/session_id/${encodeURIComponent(String(queryArg.sessionId))}/uid/${encodeURIComponent(String(queryArg.uid))}/state_uid_dest/${encodeURIComponent(String(queryArg.stateUidDest))}`,
      }),
    }),
    relationPlanSyncStateUpdateState: build.query<
      RelationPlanSyncStateUpdateStateApiResponse,
      RelationPlanSyncStateUpdateStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/sync/state-update-state/session_id/${encodeURIComponent(String(queryArg.sessionId))}/uid/${encodeURIComponent(String(queryArg.uid))}/state_uid_state/${encodeURIComponent(String(queryArg.stateUidState))}/old_state_uid_state/${encodeURIComponent(String(queryArg.oldStateUidState))}`,
      }),
    }),
    relationPlanSyncListAll: build.query<
      RelationPlanSyncListAllApiResponse,
      RelationPlanSyncListAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/sync/list-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    relationPlanSetDeleteRequest: build.mutation<
      RelationPlanSetDeleteRequestApiResponse,
      RelationPlanSetDeleteRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/set/delete_request/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}`,
        method: "PUT",
      }),
    }),
    relationPlanSetStopRequest: build.mutation<
      RelationPlanSetStopRequestApiResponse,
      RelationPlanSetStopRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/plan/set/stop_request/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/plan_id/${encodeURIComponent(String(queryArg.planId))}`,
        method: "PUT",
      }),
    }),
    relationPublicFileList: build.query<
      RelationPublicFileListApiResponse,
      RelationPublicFileListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/public/file/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
      }),
    }),
    relationPublicFileTextList: build.query<
      RelationPublicFileTextListApiResponse,
      RelationPublicFileTextListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/public/file/text/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/ids/${encodeURIComponent(String(queryArg.ids))}`,
      }),
    }),
    relationPublicFileDownload: build.query<
      RelationPublicFileDownloadApiResponse,
      RelationPublicFileDownloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/public/file/download/session_id/${encodeURIComponent(String(queryArg.sessionId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/file_id/${encodeURIComponent(String(queryArg.fileId))}`,
      }),
    }),
    relationList: build.query<RelationListApiResponse, RelationListApiArg>({
      query: (queryArg) => ({
        url: `/api/relation/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    relationCreate: build.mutation<
      RelationCreateApiResponse,
      RelationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/create/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}`,
        method: "POST",
      }),
    }),
    relationCopyRelationFiles: build.mutation<
      RelationCopyRelationFilesApiResponse,
      RelationCopyRelationFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/copy-relation-files/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/source_relation_id/${encodeURIComponent(String(queryArg.sourceRelationId))}`,
        method: "POST",
      }),
    }),
    relationDelete: build.mutation<
      RelationDeleteApiResponse,
      RelationDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
        method: "DELETE",
      }),
    }),
    relationCompletationInfo: build.query<
      RelationCompletationInfoApiResponse,
      RelationCompletationInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/completation-info/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
      }),
    }),
    relationSetComplete: build.mutation<
      RelationSetCompleteApiResponse,
      RelationSetCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/set-complete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/is_complete/${encodeURIComponent(String(queryArg.isComplete))}`,
        method: "PUT",
      }),
    }),
    relationSetSmsIdent: build.mutation<
      RelationSetSmsIdentApiResponse,
      RelationSetSmsIdentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/set-sms-ident/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}/sms_ident/${encodeURIComponent(String(queryArg.smsIdent))}`,
        method: "POST",
      }),
    }),
    relationSetUploadRequest: build.mutation<
      RelationSetUploadRequestApiResponse,
      RelationSetUploadRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/set-upload-request/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/relation_id/${encodeURIComponent(String(queryArg.relationId))}`,
        method: "POST",
      }),
    }),
    relationSyncSetUploadRequest: build.query<
      RelationSyncSetUploadRequestApiResponse,
      RelationSyncSetUploadRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/sync/set-upload-request/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}/upload_request/${encodeURIComponent(String(queryArg.uploadRequest))}`,
      }),
    }),
    relationSyncUpdateStateUid: build.query<
      RelationSyncUpdateStateUidApiResponse,
      RelationSyncUpdateStateUidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/sync/update-state-uid/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}/state_uid_dest/${encodeURIComponent(String(queryArg.stateUidDest))}/state_uid_file/${encodeURIComponent(String(queryArg.stateUidFile))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
      }),
    }),
    relationSyncOrigin: build.query<
      RelationSyncOriginApiResponse,
      RelationSyncOriginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/sync/origin/session_id/${encodeURIComponent(String(queryArg.sessionId))}/origin_installation_name/${encodeURIComponent(String(queryArg.originInstallationName))}`,
        body: queryArg.relations,
      }),
    }),
    relationSyncListAll: build.query<
      RelationSyncListAllApiResponse,
      RelationSyncListAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/relation/sync/list-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    serverOriginName: build.query<
      ServerOriginNameApiResponse,
      ServerOriginNameApiArg
    >({
      query: (queryArg) => ({
        url: `/api/server/origin_name/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    sessionCreate: build.mutation<
      SessionCreateApiResponse,
      SessionCreateApiArg
    >({
      query: () => ({ url: `/api/session/create`, method: "POST" }),
    }),
    sessionDelete: build.mutation<
      SessionDeleteApiResponse,
      SessionDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/session/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        method: "DELETE",
      }),
    }),
    sessionPermissionList: build.query<
      SessionPermissionListApiResponse,
      SessionPermissionListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/session/permission/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    sessionRefresh: build.mutation<
      SessionRefreshApiResponse,
      SessionRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/api/session/refresh/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
        method: "POST",
      }),
    }),
    sourceList: build.query<SourceListApiResponse, SourceListApiArg>({
      query: (queryArg) => ({
        url: `/api/source/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    sourceUpsert: build.query<SourceUpsertApiResponse, SourceUpsertApiArg>({
      query: (queryArg) => ({
        url: `/api/source/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}/type/${encodeURIComponent(String(queryArg["type"]))}/settings/${encodeURIComponent(String(queryArg.settings))}`,
      }),
    }),
    sourceSyncInstallation: build.query<
      SourceSyncInstallationApiResponse,
      SourceSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.sources,
      }),
    }),
    sourceDelete: build.query<SourceDeleteApiResponse, SourceDeleteApiArg>({
      query: (queryArg) => ({
        url: `/api/source/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}`,
      }),
    }),
    sourceSyncListAll: build.query<
      SourceSyncListAllApiResponse,
      SourceSyncListAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source/sync/list-all/session_id/${encodeURIComponent(String(queryArg.sessionId))}`,
      }),
    }),
    sourceSyncUpdateStateUid: build.query<
      SourceSyncUpdateStateUidApiResponse,
      SourceSyncUpdateStateUidApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source/sync/update-state-uid/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}/name/${encodeURIComponent(String(queryArg.name))}/state_uid/${encodeURIComponent(String(queryArg.stateUid))}`,
      }),
    }),
    sourceSyncListInstallation: build.query<
      SourceSyncListInstallationApiResponse,
      SourceSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source/sync/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    sourceTagsList: build.query<
      SourceTagsListApiResponse,
      SourceTagsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source_tags/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_id/${encodeURIComponent(String(queryArg.sourceId))}`,
      }),
    }),
    sourceTagsSyncListInstallation: build.query<
      SourceTagsSyncListInstallationApiResponse,
      SourceTagsSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source_tags/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    sourceTagsUpsert: build.query<
      SourceTagsUpsertApiResponse,
      SourceTagsUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source_tags/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_id/${encodeURIComponent(String(queryArg.sourceId))}/tag_name/${encodeURIComponent(String(queryArg.tagName))}/tag_type/${encodeURIComponent(String(queryArg.tagType))}/(tag_value/${encodeURIComponent(String(queryArg.tagValue))})`,
      }),
    }),
    sourceTagsSyncInstallation: build.query<
      SourceTagsSyncInstallationApiResponse,
      SourceTagsSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/source_tags/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.sourceTags,
      }),
    }),
    supplierList: build.query<SupplierListApiResponse, SupplierListApiArg>({
      query: (queryArg) => ({
        url: `/api/supplier/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_id/${encodeURIComponent(String(queryArg.sourceId))}`,
      }),
    }),
    supplierSyncListInstallation: build.query<
      SupplierSyncListInstallationApiResponse,
      SupplierSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    supplierSyncInstallation: build.query<
      SupplierSyncInstallationApiResponse,
      SupplierSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.suppliers,
      }),
    }),
    supplierUpsert: build.query<
      SupplierUpsertApiResponse,
      SupplierUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_id/${encodeURIComponent(String(queryArg.sourceId))}/name/${encodeURIComponent(String(queryArg.name))}/(description/${encodeURIComponent(String(queryArg.description))})`,
      }),
    }),
    supplierDelete: build.query<
      SupplierDeleteApiResponse,
      SupplierDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/source_id/${encodeURIComponent(String(queryArg.sourceId))}/name/${encodeURIComponent(String(queryArg.name))}`,
      }),
    }),
    supplierUnitsList: build.query<
      SupplierUnitsListApiResponse,
      SupplierUnitsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier_units/list/session_id/${encodeURIComponent(String(queryArg.sessionId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}`,
      }),
    }),
    supplierUnitsListInstallation: build.query<
      SupplierUnitsListInstallationApiResponse,
      SupplierUnitsListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier_units/list-installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    supplierUnitsSyncListInstallation: build.query<
      SupplierUnitsSyncListInstallationApiResponse,
      SupplierUnitsSyncListInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier_units/sync/list/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
      }),
    }),
    supplierUnitsSyncInstallation: build.query<
      SupplierUnitsSyncInstallationApiResponse,
      SupplierUnitsSyncInstallationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier_units/sync/installation/session_id/${encodeURIComponent(String(queryArg.sessionId))}/installation_id/${encodeURIComponent(String(queryArg.installationId))}`,
        body: queryArg.supplierUnits,
      }),
    }),
    supplierUnitsUpsert: build.query<
      SupplierUnitsUpsertApiResponse,
      SupplierUnitsUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier_units/upsert/session_id/${encodeURIComponent(String(queryArg.sessionId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}/type/${encodeURIComponent(String(queryArg["type"]))}/name/${encodeURIComponent(String(queryArg.name))}/order_position/${encodeURIComponent(String(queryArg.orderPosition))}/unit/${encodeURIComponent(String(queryArg.unit))}/precision/${encodeURIComponent(String(queryArg.precision))}/description/${encodeURIComponent(String(queryArg.description))}/(format/${encodeURIComponent(String(queryArg.format))})/for_service/${encodeURIComponent(String(queryArg.forService))}`,
      }),
    }),
    supplierUnitsDelete: build.query<
      SupplierUnitsDeleteApiResponse,
      SupplierUnitsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/supplier_units/delete/session_id/${encodeURIComponent(String(queryArg.sessionId))}/supplier_id/${encodeURIComponent(String(queryArg.supplierId))}/name/${encodeURIComponent(String(queryArg.name))}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as citywebApi };
export type AppPermissionListApiResponse =
  /** status 200 OK result - array of type 'AppPermission' instances */ AppPermissions;
export type AppPermissionListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type AppPermissionUpsertApiResponse =
  /** status 200 OK result - single instance of type 'AppPermission' */ AppPermission;
export type AppPermissionUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Code */
  code: string;
  /** Text */
  text: string;
  /** Description */
  description?: string;
};
export type AppPermissionDeleteApiResponse =
  /** status 200 OK result - single instance of type 'AppPermission' */ AppPermission;
export type AppPermissionDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Code */
  code: string;
};
export type AppPermissionSetPermissionApiResponse =
  /** status 200 OK result - array of type 'AppPermission' instances */ AppPermissions;
export type AppPermissionSetPermissionApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  appPermissions: AppPermissions;
};
export type AppPermissionSetPermissionCustomApiResponse =
  /** status 200 OK result - array of type 'AppPermission' instances */ AppPermissions;
export type AppPermissionSetPermissionCustomApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  appPermissions: AppPermissions;
};
export type AppPermissionSystemListApiResponse =
  /** status 200 OK result - array of type 'AppPermission' instances */ AppPermissions;
export type AppPermissionSystemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type AppPermissionSystemUpsertApiResponse =
  /** status 200 OK result - single instance of type 'AppPermission' */ AppPermission;
export type AppPermissionSystemUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Code */
  code: string;
  /** Text */
  text: string;
  /** Description */
  description?: string;
};
export type AppPermissionSystemDeleteApiResponse =
  /** status 200 OK result - single instance of type 'AppPermission' */ AppPermission;
export type AppPermissionSystemDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Code */
  code: string;
};
export type AppPermissionSystemSetPermissionApiResponse =
  /** status 200 OK result - array of type 'AppPermission' instances */ AppPermissions;
export type AppPermissionSystemSetPermissionApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  appPermissions: AppPermissions;
};
export type AudioStorageGeneralFolderListApiResponse =
  /** status 200 OK result - array of type 'StorageFolderItem' instances */ StorageFolderItems;
export type AudioStorageGeneralFolderListApiArg = {
  /** Session id */
  sessionId: string;
  /** Folder name */
  folderName: string;
};
export type AudioStorageGeneralSaveToRelationApiResponse =
  /** status 200 OK result - single instance of type 'StorageFolderItem' */ StorageFolderItem;
export type AudioStorageGeneralSaveToRelationApiArg = {
  /** Session id */
  sessionId: string;
  /** File name */
  fileName: string;
  /** Relation installation id */
  relationInstallationId: number;
  /** Relation id */
  relationId: number;
  /** File relation playing order position */
  orderPosition: number;
  /** File can be played in public domain */
  isPublic: number;
};
export type AudioStorageUserFolderListApiResponse =
  /** status 200 OK result - array of type 'StorageFolderItem' instances */ StorageFolderItems;
export type AudioStorageUserFolderListApiArg = {
  /** Session id */
  sessionId: string;
  /** Folder name */
  folderName: string;
};
export type AudioStorageUserFolderCreateApiResponse =
  /** status 200 OK result - single instance of type 'StorageFolderItem' */ StorageFolderItem;
export type AudioStorageUserFolderCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Folder name */
  folderName: string;
};
export type AudioStorageUserUsageApiResponse =
  /** status 200 OK result - single instance of type 'StorageUsage' */ StorageUsage;
export type AudioStorageUserUsageApiArg = {
  /** Session id */
  sessionId: string;
};
export type AudioStorageUserFileUploadApiResponse =
  /** status 200 OK result - single instance of type 'StorageFolderItem' */ StorageFolderItem;
export type AudioStorageUserFileUploadApiArg = {
  /** Session id */
  sessionId: string;
  /** File name */
  fileName: string;
  body: Blob;
};
export type AudioStorageUserFileDownloadApiResponse =
  /** status 200 OK result */ Blob;
export type AudioStorageUserFileDownloadApiArg = {
  /** Session id */
  sessionId: string;
  /** File name */
  fileName: string;
};
export type AudioStorageUserItemRenameApiResponse =
  /** status 200 OK result - single instance of type 'StorageFolderItem' */ StorageFolderItem;
export type AudioStorageUserItemRenameApiArg = {
  /** Session id */
  sessionId: string;
  /** From name */
  fromName: string;
  /** To name */
  toName: string;
};
export type AudioStorageUserItemDeleteApiResponse =
  /** status 200 OK result - single instance of type 'StorageFolderItem' */ StorageFolderItem;
export type AudioStorageUserItemDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Item name */
  itemName: string;
};
export type AudioStorageUserSaveToRelationApiResponse =
  /** status 200 OK result - single instance of type 'StorageFolderItem' */ StorageFolderItem;
export type AudioStorageUserSaveToRelationApiArg = {
  /** Session id */
  sessionId: string;
  /** File name */
  fileName: string;
  /** Relation installation id */
  relationInstallationId: number;
  /** Relation id */
  relationId: number;
  /** File relation playing order position */
  orderPosition: number;
  /** File can be played in public domain */
  isPublic: number;
};
export type AuthenticationLoginApiResponse =
  /** status 200 OK result - single instance of type 'UserLoggedIn' */ UserLoggedIn;
export type AuthenticationLoginApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation name */
  installationName: string;
  /** Username */
  username: string;
  userLoginPassword: UserLoginPassword;
};
export type AuthenticationTokenApiResponse =
  /** status 200 OK result - single instance of type 'UserLoggedIn' */ UserLoggedIn;
export type AuthenticationTokenApiArg = {
  /** Session id */
  sessionId: string;
  /** Client id */
  clientId?: string;
  userLoginToken: UserLoginToken;
};
export type AuthenticationRefeshAccessApiResponse =
  /** status 200 OK result - single instance of type 'UserLoggedIn' */ UserLoggedIn;
export type AuthenticationRefeshAccessApiArg = {
  /** Session id */
  sessionId: string;
};
export type AuthenticationPublicPlanTokenApiResponse =
  /** status 200 OK result - single instance of type 'PlanPublicInstallation' */ PlanPublicInstallation;
export type AuthenticationPublicPlanTokenApiArg = {
  /** Session id */
  sessionId: string;
  userLoginToken: UserLoginToken;
};
export type AuthenticationSystemLoginApiResponse =
  /** status 200 OK result - single instance of type 'UserLoggedIn' */ UserLoggedIn;
export type AuthenticationSystemLoginApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation name */
  installationName: number;
  /** Username */
  username: string;
  /** Password */
  password: string;
};
export type DeviceDestinationListForInstallationsApiResponse =
  /** status 200 OK result - array of type 'DeviceDestination' instances */ DeviceDestinations;
export type DeviceDestinationListForInstallationsApiArg = {
  /** Session id */
  sessionId: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type DeviceBackinfoDestinationListForInstallationsApiResponse =
  /** status 200 OK result - array of type 'DeviceDestination' instances */ DeviceDestinations;
export type DeviceBackinfoDestinationListForInstallationsApiArg = {
  /** Session id */
  sessionId: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type DeviceIntegrationDestinationListForInstallationsApiResponse =
  /** status 200 OK result - array of type 'DeviceDestination' instances */ DeviceDestinations;
export type DeviceIntegrationDestinationListForInstallationsApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DevicesImportSettingsListApiResponse =
  /** status 200 OK result - array of type 'DeviceImportSettingsList' instances */ DeviceImportSettingsLists;
export type DevicesImportSettingsListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DevicesIntegrationListApiResponse =
  /** status 200 OK result - array of type 'DeviceImportIntegration' instances */ DeviceImportIntegrations;
export type DevicesIntegrationListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DevicesImportSettingsDuplicateListApiResponse =
  /** status 200 OK result - array of type 'DeviceImportSettings' instances */ DeviceImportSettingss;
export type DevicesImportSettingsDuplicateListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Device id */
  deviceId: number;
};
export type DevicesImportSettingsInstallationsAllListApiResponse =
  /** status 200 OK result - array of type 'DeviceImportSettingsInstallation' instances */ DeviceImportSettingsInstallations;
export type DevicesImportSettingsInstallationsAllListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DevicesImportSettingsUpsertApiResponse =
  /** status 200 OK result - single instance of type 'DeviceImportSettings' */ DeviceImportSettings;
export type DevicesImportSettingsUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Device id */
  deviceId: number;
};
export type DevicesImportSettingsDeleteApiResponse =
  /** status 200 OK result - single instance of type 'DeviceImportSettings' */ DeviceImportSettings;
export type DevicesImportSettingsDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Installation id */
  installationId: number;
};
export type DevicesImportListApiResponse =
  /** status 200 OK result - array of type 'DeviceImport' instances */ DeviceImports;
export type DevicesImportListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DevicesImportDestinationListApiResponse =
  /** status 200 OK result - array of type 'DeviceImport' instances */ DeviceImports;
export type DevicesImportDestinationListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DevicesImportUpsertApiResponse =
  /** status 200 OK result - single instance of type 'DeviceImport' */ DeviceImport;
export type DevicesImportUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Source name */
  sourceName: string;
  /** Supplier name */
  supplierName: string;
  /** Installation id */
  installationId: number;
  /** Locality name */
  localityName: string;
  /** Device name */
  deviceName: string;
  /** Unit type */
  unitType: string;
  /** Unit order position */
  unitOrderPosition?: number;
  /** Gps lat */
  gpsLat?: number | null;
  /** Gps lng */
  gpsLng?: number | null;
  /** Url web */
  urlWeb: string;
  /** Url data */
  urlData: string;
  /** Rule name */
  ruleName?: string | null;
  /** Unit unit */
  unitUnit: string;
  /** Unit precision */
  unitPrecision: number;
  /** Unit name */
  unitName: string;
  /** Unit format */
  unitFormat: string;
  /** Unit for service */
  unitForService: number;
};
export type DevicesImportDeleteApiResponse =
  /** status 200 OK result - single instance of type 'DeviceImport' */ DeviceImport;
export type DevicesImportDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Id */
  id: number;
  /** Installation id */
  installationId: number;
};
export type DevicesImportDeviceImportedListApiResponse =
  /** status 200 OK result - array of type 'DeviceImportSettingsList' instances */ DeviceImportSettingsLists;
export type DevicesImportDeviceImportedListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Device installation id */
  deviceInstallationId: number;
};
export type DeviceImporterListApiResponse =
  /** status 200 OK result - array of type 'DeviceImporter' instances */ DeviceImporters;
export type DeviceImporterListApiArg = {
  /** Session id */
  sessionId: string;
  /** Source name */
  sourceName: string;
  /** Supplier name */
  supplierName: string;
};
export type DeviceListApiResponse =
  /** status 200 OK result - array of type 'Device' instances */ Devices;
export type DeviceListApiArg = {
  /** Session id */
  sessionId: string;
  /** Locality id */
  localityId: number;
};
export type DeviceSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'Device' instances */ Devices;
export type DeviceSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Device' */ Device;
export type DeviceUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Locality id */
  localityId: number;
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
  /** Gps lat */
  gpsLat?: number | null;
  /** Gps lng */
  gpsLng?: number | null;
  /** Order position */
  orderPosition?: number | null;
};
export type DeviceDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Device' */ Device;
export type DeviceDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Locality id */
  localityId: number;
  /** Name */
  name: string;
};
export type DeviceSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'Device' instances */ Devices;
export type DeviceSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  devices: Devices;
};
export type DeviceParentListApiResponse =
  /** status 200 OK result - single instance of type 'DeviceParents' */ DeviceParents;
export type DeviceParentListApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
};
export type DeviceRecordListApiResponse =
  /** status 200 OK result - array of type 'DeviceRecord' instances */ DeviceRecords;
export type DeviceRecordListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Time from */
  timeFrom: string;
  /** Time to */
  timeTo: string;
  /** Unit type */
  unitType: string;
  /** Device id */
  deviceId: number;
};
export type DeviceRecordSyncListDeviceApiResponse =
  /** status 200 OK result - array of type 'DeviceRecord' instances */ DeviceRecords;
export type DeviceRecordSyncListDeviceApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** From time */
  fromTime: string;
  /** To time */
  toTime: string;
};
export type DeviceRecordSyncDeviceApiResponse =
  /** status 200 OK result - array of type 'DeviceRecord' instances */ DeviceRecords;
export type DeviceRecordSyncDeviceApiArg = {
  /** Session id */
  sessionId: string;
  deviceRecords: DeviceRecords;
};
export type DeviceRecordSyncUpsertDeviceApiResponse =
  /** status 200 OK result - single instance of type 'DeviceRecord' */ DeviceRecord;
export type DeviceRecordSyncUpsertDeviceApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Unit type */
  unitType: string;
  /** Value */
  value: number;
  /** Time utc */
  timeUtc: string;
};
export type DeviceRecordImportRecordsApiResponse =
  /** status 200 OK result - array of type 'DeviceRecord' instances */ DeviceRecords;
export type DeviceRecordImportRecordsApiArg = {
  /** Session id */
  sessionId: string;
  deviceRecords: DeviceRecords;
};
export type DeviceRecordImportRecordsByHierarchyApiResponse =
  /** status 200 OK result - array of type 'DeviceRecord' instances */ DeviceRecords;
export type DeviceRecordImportRecordsByHierarchyApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Source name */
  sourceName: string;
  /** Supplier name */
  supplierName: string;
  /** Device name */
  deviceName: string;
  deviceRecords: DeviceRecords;
};
export type DeviceRecordLastListApiResponse =
  /** status 200 OK result - array of type 'DeviceRecordLast' instances */ DeviceRecordLasts;
export type DeviceRecordLastListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceTagsListApiResponse =
  /** status 200 OK result - array of type 'DeviceTag' instances */ DeviceTags;
export type DeviceTagsListApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
};
export type DeviceTagsSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'DeviceTag' instances */ DeviceTags;
export type DeviceTagsSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceTagsUpsertApiResponse =
  /** status 200 OK result - single instance of type 'DeviceTag' */ DeviceTag;
export type DeviceTagsUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Tag name */
  tagName: string;
  /** Tag value */
  tagValue?: string | null;
};
export type DeviceTagsDeleteApiResponse =
  /** status 200 OK result - single instance of type 'DeviceTag' */ DeviceTag;
export type DeviceTagsDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Tag name */
  tagName: string;
};
export type DeviceTagsSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'DeviceTag' instances */ DeviceTags;
export type DeviceTagsSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  deviceTags: DeviceTags;
};
export type DeviceUnitLevelRuleItemListApiResponse =
  /** status 200 OK result - array of type 'DeviceUnitLevelRuleItem' instances */ DeviceUnitLevelRuleItems;
export type DeviceUnitLevelRuleItemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Unit type */
  unitType: string;
  /** Installation id */
  installationId: number;
};
export type DeviceUnitLevelRuleListApiResponse =
  /** status 200 OK result - array of type 'DeviceUnitLevelRule' instances */ DeviceUnitLevelRules;
export type DeviceUnitLevelRuleListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceUnitLevelRuleItemListInstallationApiResponse =
  /** status 200 OK result - array of type 'DeviceUnitLevelRuleItem' instances */ DeviceUnitLevelRuleItems;
export type DeviceUnitLevelRuleItemListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceUnitLevelRuleUpsertApiResponse =
  /** status 200 OK result - single instance of type 'DeviceUnit' */ DeviceUnit;
export type DeviceUnitLevelRuleUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation id */
  originInstallationId: number;
  /** Device unit id */
  deviceUnitId: number;
  /** Rule name */
  ruleName: string;
  /** Device id */
  deviceId: number;
};
export type DeviceUnitLevelRuleItemUpsertApiResponse =
  /** status 200 OK result - single instance of type 'DeviceUnit' */ DeviceUnit;
export type DeviceUnitLevelRuleItemUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
};
export type DeviceUnitLevelRuleDeleteApiResponse =
  /** status 200 OK result - single instance of type 'DeviceUnit' */ DeviceUnit;
export type DeviceUnitLevelRuleDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Unit type */
  unitType: string;
};
export type DeviceUnitLevelRuleItemSynchronizeApiResponse =
  /** status 200 OK result - array of type 'DeviceUnitLevelRuleItem' instances */ DeviceUnitLevelRuleItems;
export type DeviceUnitLevelRuleItemSynchronizeApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Unit type */
  unitType: string;
  deviceUnitLevelRuleItems: DeviceUnitLevelRuleItems;
};
export type DeviceUnitLevelRuleGetIdsApiResponse =
  /** status 200 OK result - array of type 'InstallationDeviceId' instances */ InstallationDeviceIds;
export type DeviceUnitLevelRuleGetIdsApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Device id */
  deviceId: number;
};
export type DeviceUnitListApiResponse =
  /** status 200 OK result - array of type 'DeviceUnit' instances */ DeviceUnits;
export type DeviceUnitListApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
};
export type DeviceUnitSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'DeviceUnit' instances */ DeviceUnits;
export type DeviceUnitSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceUnitListInstallationApiResponse =
  /** status 200 OK result - array of type 'DeviceUnit' instances */ DeviceUnits;
export type DeviceUnitListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type DeviceUnitUpsertApiResponse =
  /** status 200 OK result - single instance of type 'DeviceUnit' */ DeviceUnit;
export type DeviceUnitUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Locality id */
  localityId: number;
  /** Supplier id */
  supplierId: number;
  /** Unit type */
  unitType: string;
};
export type DeviceUnitDeleteApiResponse =
  /** status 200 OK result - single instance of type 'DeviceUnit' */ DeviceUnit;
export type DeviceUnitDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Device id */
  deviceId: number;
  /** Unit type */
  unitType: string;
};
export type DeviceUnitSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'DeviceUnit' instances */ DeviceUnits;
export type DeviceUnitSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  deviceUnits: DeviceUnits;
};
export type DeviceUnitSyncGetIdsApiResponse =
  /** status 200 OK result - array of type 'InstallationDeviceId' instances */ InstallationDeviceIds;
export type DeviceUnitSyncGetIdsApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type InstallationCommandRequestDestinationVolumeApiResponse =
  /** status 200 OK result - single instance of type 'InstallationCommandRequest' */ InstallationCommandRequest;
export type InstallationCommandRequestDestinationVolumeApiArg = {
  /** Command */
  command: string;
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type InstallationCommandReplyApiResponse =
  /** status 200 OK result - single instance of type 'InstallationCommandReply' */ InstallationCommandReply;
export type InstallationCommandReplyApiArg = {
  /** Command */
  command: string;
  /** Command parameter */
  commandParameter: string;
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Request token */
  requestToken: string;
  /** Origin server */
  originServer: string;
  /** Result ok */
  resultOk: number;
  /** Result message */
  resultMessage: string;
};
export type InstallationCommandSyncRequestApiResponse =
  /** status 200 OK result - single instance of type 'InstallationCommandRequest' */ InstallationCommandRequest;
export type InstallationCommandSyncRequestApiArg = {
  /** Command */
  command: string;
  /** Command parameter */
  commandParameter: string;
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Request token */
  requestToken: string;
  /** Origin server */
  originServer: string;
  /** User name */
  userName: string;
};
export type InstallationLocalUpsertAndReplicateApiResponse =
  /** status 200 OK result - single instance of type 'Installation' */ Installation;
export type InstallationLocalUpsertAndReplicateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation name */
  installationName: string;
};
export type InstallationLocalListAllApiResponse =
  /** status 200 OK result - array of type 'Installation' instances */ Installations;
export type InstallationLocalListAllApiArg = {
  /** Session id */
  sessionId: string;
};
export type InstallationsGuessApiResponse =
  /** status 200 OK result - array of type 'Installation' instances */ Installations;
export type InstallationsGuessApiArg = {
  /** Session id */
  sessionId: string;
};
export type InstallationListAttachSystemApiResponse =
  /** status 200 OK result - array of type 'Installation' instances */ Installations;
export type InstallationListAttachSystemApiArg = {
  /** Session id */
  sessionId: string;
};
export type InstallationListApiResponse =
  /** status 200 OK result - array of type 'Installation' instances */ Installations;
export type InstallationListApiArg = {
  /** Session id */
  sessionId: string;
};
export type InstallationsGuessUserOnlyApiResponse =
  /** status 200 OK result - array of type 'Installation' instances */ Installations;
export type InstallationsGuessUserOnlyApiArg = {
  /** Session id */
  sessionId: string;
};
export type InstallationUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Installation' */ Installation;
export type InstallationUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation name */
  installationName: string;
};
export type InstallationDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Installation' */ Installation;
export type InstallationDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type InstallationGetByNameApiResponse =
  /** status 200 OK result - single instance of type 'Installation' */ Installation;
export type InstallationGetByNameApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation name */
  installationName: string;
};
export type InstallationImportedDeviceListApiResponse =
  /** status 200 OK result - array of type 'InstallationImported' instances */ InstallationImporteds;
export type InstallationImportedDeviceListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type InstallationSyncImportedDeviceInstApiResponse =
  /** status 200 OK result - array of type 'InstallationImported' instances */ InstallationImporteds;
export type InstallationSyncImportedDeviceInstApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  installationImporteds: InstallationImporteds;
};
export type InstallationTagsListSectionNoKeysApiResponse =
  /** status 200 OK result - array of type 'InstallationTag' instances */ InstallationTags;
export type InstallationTagsListSectionNoKeysApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Config */
  config: string;
  /** Section */
  section: string;
};
export type InstallationTagsListTextToSpeechApiResponse =
  /** status 200 OK result - array of type 'InstallationTag' instances */ InstallationTags;
export type InstallationTagsListTextToSpeechApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type InstallationTagsUpsertTextToSpeechApiResponse =
  /** status 200 OK result - single instance of type 'InstallationTag' */ InstallationTag;
export type InstallationTagsUpsertTextToSpeechApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Tag name */
  tagName: string;
  /** Tag value */
  tagValue?: string | null;
};
export type InstallationTagsUpsertLicencingApiResponse =
  /** status 200 OK result - single instance of type 'InstallationTag' */ InstallationTag;
export type InstallationTagsUpsertLicencingApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Tag name */
  tagName: string;
  /** Tag value */
  tagValue?: string | null;
};
export type InstallationTagsSyncSectionApiResponse =
  /** status 200 OK result - array of type 'InstallationTag' instances */ InstallationTags;
export type InstallationTagsSyncSectionApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Config */
  config: string;
  /** Section */
  section: string;
  installationTagSectionItemNews: InstallationTagSectionItemNews;
};
export type InstallationTagsSyncListApiResponse =
  /** status 200 OK result - array of type 'InstallationTag' instances */ InstallationTags;
export type InstallationTagsSyncListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Origin installation name */
  originInstallationName: string;
};
export type InstallationTagsSyncInstallationOriginApiResponse =
  /** status 200 OK result - array of type 'InstallationTag' instances */ InstallationTags;
export type InstallationTagsSyncInstallationOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** State uid remote */
  stateUidRemote: string;
  installationTags: InstallationTags;
};
export type InstallatorRegisterLocalInstallationApiResponse =
  /** status 200 OK result - single instance of type 'InstallationRegistration' */ InstallationRegistration;
export type InstallatorRegisterLocalInstallationApiArg = {
  /** Create installation name */
  createInstallationName: string;
};
export type InstallatorRegisterInstallationApiResponse =
  /** status 200 OK result - single instance of type 'InstallationRegistration' */ InstallationRegistration;
export type InstallatorRegisterInstallationApiArg = {
  /** Login installation name */
  loginInstallationName: string;
  /** Login username */
  loginUsername: string;
  /** Create installation name */
  createInstallationName: string;
  userLoginPassword: UserLoginPassword;
};
export type LocalityListApiResponse =
  /** status 200 OK result - array of type 'Locality' instances */ Localitys;
export type LocalityListApiArg = {
  /** Session id */
  sessionId: string;
  /** Supplier id */
  supplierId: number;
};
export type LocalityListInstallationsApiResponse =
  /** status 200 OK result - array of type 'Locality' instances */ Localitys;
export type LocalityListInstallationsApiArg = {
  /** Session id */
  sessionId: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type LocalitySyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'Locality' instances */ Localitys;
export type LocalitySyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type LocalityUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Locality' */ Locality;
export type LocalityUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Supplier id */
  supplierId: number;
  /** Address */
  address?: string | null;
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
  /** Kind */
  kind?: string | null;
  /** Order position */
  orderPosition?: number | null;
};
export type LocalityDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Locality' */ Locality;
export type LocalityDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Supplier id */
  supplierId: number;
  /** Name */
  name: string;
};
export type LocalitySyncInstallationApiResponse =
  /** status 200 OK result - array of type 'Locality' instances */ Localitys;
export type LocalitySyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  localitys: Localitys;
};
export type MqttInternalBridgeAuthApiResponse =
  /** status 200 OK result - single instance of type 'MqttBridgeAuth' */ MqttBridgeAuth;
export type MqttInternalBridgeAuthApiArg = {
  /** Installation name */
  installationName: string;
  /** Login */
  login: string;
  /** Password */
  password: string;
};
export type MqttWebsocketGenerateOneTimeSessionPwdApiResponse =
  /** status 200 OK result - single instance of type 'MqttWebSocketOneTimePwd' */ MqttWebSocketOneTimePwd;
export type MqttWebsocketGenerateOneTimeSessionPwdApiArg = {
  /** Session id */
  sessionId: string;
};
export type MqttInternalWebsocketAuthApiResponse =
  /** status 200 OK result - array of type 'MqttWebSocketTopic' instances */ MqttWebSocketTopics;
export type MqttInternalWebsocketAuthApiArg = {
  /** Session id */
  sessionId: string;
  /** Password */
  password: string;
};
export type MqttInternalWebsocketTopicListApiResponse =
  /** status 200 OK result - array of type 'MqttWebSocketTopic' instances */ MqttWebSocketTopics;
export type MqttInternalWebsocketTopicListApiArg = {
  /** Session id */
  sessionId: string;
};
export type SessionNotificationMqttTopicListApiResponse =
  /** status 200 OK result - array of type 'MqttWebSocketTopic' instances */ MqttWebSocketTopics;
export type SessionNotificationMqttTopicListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SessionNotificationMqttTopicListAllApiResponse =
  /** status 200 OK result - array of type 'MqttWebSocketTopic' instances */ MqttWebSocketTopics;
export type SessionNotificationMqttTopicListAllApiArg = {
  /** Session id */
  sessionId: string;
};
export type PermissionListApiResponse =
  /** status 200 OK result - array of type 'Permission' instances */ Permissions;
export type PermissionListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
};
export type PermissionUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Permission' */ Permission;
export type PermissionUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  /** Code */
  code: string;
  /** For installation id */
  forInstallationId: number;
};
export type PermissionSetAllApiResponse =
  /** status 200 OK result - array of type 'Permission' instances */ Permissions;
export type PermissionSetAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  sidPermissions: SidPermissions;
};
export type PermissionInternalListApiResponse =
  /** status 200 OK result - array of type 'Permission' instances */ Permissions;
export type PermissionInternalListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
};
export type PermissionSystemListApiResponse =
  /** status 200 OK result - array of type 'Permission' instances */ Permissions;
export type PermissionSystemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
};
export type PermissionSystemSetAllApiResponse =
  /** status 200 OK result - array of type 'Permission' instances */ Permissions;
export type PermissionSystemSetAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  sidPermissions: SidPermissions;
};
export type PermissionUserListApiResponse =
  /** status 200 OK result - array of type 'PermissionUser' instances */ PermissionUsers;
export type PermissionUserListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PermissionGroupListApiResponse =
  /** status 200 OK result - array of type 'PermissionGroup' instances */ PermissionGroups;
export type PermissionGroupListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type PermissionGroupCreateApiResponse =
  /** status 200 OK result - single instance of type 'PermissionGroup' */ PermissionGroup;
export type PermissionGroupCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
};
export type PermissionGroupUpdateApiResponse =
  /** status 200 OK result - single instance of type 'PermissionGroup' */ PermissionGroup;
export type PermissionGroupUpdateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Name */
  name: string;
};
export type PermissionGroupDeleteApiResponse =
  /** status 200 OK result - single instance of type 'PermissionGroup' */ PermissionGroup;
export type PermissionGroupDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PermissionGroupInternalListApiResponse =
  /** status 200 OK result - array of type 'PermissionGroup' instances */ PermissionGroups;
export type PermissionGroupInternalListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type PermissionGroupSystemListApiResponse =
  /** status 200 OK result - array of type 'PermissionGroup' instances */ PermissionGroups;
export type PermissionGroupSystemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type PermissionGroupSystemCreateApiResponse =
  /** status 200 OK result - single instance of type 'PermissionGroup' */ PermissionGroup;
export type PermissionGroupSystemCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
};
export type PermissionGroupSystemUpdateApiResponse =
  /** status 200 OK result - single instance of type 'PermissionGroup' */ PermissionGroup;
export type PermissionGroupSystemUpdateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Name */
  name: string;
};
export type PermissionGroupSystemDeleteApiResponse =
  /** status 200 OK result - single instance of type 'PermissionGroup' */ PermissionGroup;
export type PermissionGroupSystemDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PermissionLocalityListApiResponse =
  /** status 200 OK result - array of type 'PermissionLocality' instances */ PermissionLocalitys;
export type PermissionLocalityListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type PermissionLocalitySetAllApiResponse =
  /** status 200 OK result - array of type 'PermissionLocalityDest' instances */ PermissionLocalityDests;
export type PermissionLocalitySetAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  permissionLocalityDests: PermissionLocalityDests;
};
export type PermissionLocalityListUserApiResponse =
  /** status 200 OK result - array of type 'PermissionUserLocality' instances */ PermissionUserLocalitys;
export type PermissionLocalityListUserApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type PermissionLocalitySystemListApiResponse =
  /** status 200 OK result - array of type 'PermissionLocality' instances */ PermissionLocalitys;
export type PermissionLocalitySystemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type PermissionLocalitySystemSetAllApiResponse =
  /** status 200 OK result - array of type 'PermissionLocalityDest' instances */ PermissionLocalityDests;
export type PermissionLocalitySystemSetAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  permissionLocalityDests: PermissionLocalityDests;
};
export type PermissionLocalitySystemListUserApiResponse =
  /** status 200 OK result - array of type 'PermissionUserLocality' instances */ PermissionUserLocalitys;
export type PermissionLocalitySystemListUserApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** For installation ids */
  forInstallationIds: string;
};
export type PermissionTreeListUserApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeListUserApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid user */
  sidUser: string;
};
export type PermissionTreeListGroupApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeListGroupApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
};
export type PermissionTreeSetUserAllApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeSetUserAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid user */
  sidUser: string;
  permissionGroupKeys: PermissionGroupKeys;
};
export type PermissionTreeSetGroupAllApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeSetGroupAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  permissionGroupKeys: PermissionGroupKeys;
};
export type PermissionTreeInternalListUserApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeInternalListUserApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid user */
  sidUser: string;
};
export type PermissionTreeInternalListGroupApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeInternalListGroupApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
};
export type PermissionTreeSystemListUserApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeSystemListUserApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid user */
  sidUser: string;
};
export type PermissionTreeSystemListGroupApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeSystemListGroupApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
};
export type PermissionTreeSystemSetUserAllApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeSystemSetUserAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid user */
  sidUser: string;
  permissionGroupKeys: PermissionGroupKeys;
};
export type PermissionTreeSystemSetGroupAllApiResponse =
  /** status 200 OK result - array of type 'PermissionTreeItem' instances */ PermissionTreeItems;
export type PermissionTreeSystemSetGroupAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid group */
  sidGroup: string;
  permissionGroupKeys: PermissionGroupKeys;
};
export type PersonGetByLoginApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonGetByLoginApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Login */
  login: string;
};
export type PersonListApiResponse =
  /** status 200 OK result - array of type 'Person' instances */ Persons;
export type PersonListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type PersonCreateApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Firstname */
  firstname: string;
  /** Surname */
  surname: string;
  /** Description */
  description: string;
};
export type PersonUpdateApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonUpdateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Firstname */
  firstname: string;
  /** Surname */
  surname: string;
  /** Description */
  description: string;
};
export type PersonDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonCredentialsGetApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonCredentialsGetApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonCredentialsGetSidsApiResponse =
  /** status 200 OK result - array of type 'PersonCredentials' instances */ PersonCredentialss;
export type PersonCredentialsGetSidsApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sids */
  sids: string;
};
export type PersonCredentialsUpsertWithoutPasswordApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonCredentialsUpsertWithoutPasswordApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Login */
  login: string;
  /** Login enabled */
  loginEnabled: number;
  /** Password set enabled */
  passwordSetEnabled: number;
  /** Password set required */
  passwordSetRequired: number;
};
export type PersonCredentialsUpdatePasswordApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonCredentialsUpdatePasswordApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  userLoginPassword: UserLoginPassword;
};
export type PersonCredentialsSelfupdatePasswordApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonCredentialsSelfupdatePasswordApiArg = {
  /** Session id */
  sessionId: string;
  userLoginChangePassword: UserLoginChangePassword;
};
export type PersonTokenUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonTokenUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  userLoginToken: UserLoginToken;
};
export type PersonCredentialsDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonCredentialsDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonInternalListApiResponse =
  /** status 200 OK result - array of type 'Person' instances */ Persons;
export type PersonInternalListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type PersonSystemGetByLoginApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonSystemGetByLoginApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Login */
  login: string;
};
export type PersonSystemListApiResponse =
  /** status 200 OK result - array of type 'Person' instances */ Persons;
export type PersonSystemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type PersonSystemCreateApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonSystemCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Firstname */
  firstname: string;
  /** Surname */
  surname: string;
  /** Description */
  description: string;
};
export type PersonSystemUpdateApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonSystemUpdateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Firstname */
  firstname: string;
  /** Surname */
  surname: string;
  /** Description */
  description: string;
};
export type PersonSystemDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonSystemDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonSystemCredentialsGetApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonSystemCredentialsGetApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonSystemCredentialsGetSidsApiResponse =
  /** status 200 OK result - array of type 'PersonCredentials' instances */ PersonCredentialss;
export type PersonSystemCredentialsGetSidsApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sids */
  sids: string;
};
export type PersonSystemCredentialsUpsertWithoutPasswordApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonSystemCredentialsUpsertWithoutPasswordApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Login */
  login: string;
  /** Login enabled */
  loginEnabled: number;
  /** Password set enabled */
  passwordSetEnabled: number;
  /** Password set required */
  passwordSetRequired: number;
};
export type PersonSystemCredentialsUpdatePasswordApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonSystemCredentialsUpdatePasswordApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  userLoginPassword: UserLoginPassword;
};
export type PersonSystemCredentialsUpdateWithoutPasswordApiResponse =
  /** status 200 OK result - single instance of type 'PersonCredentials' */ PersonCredentials;
export type PersonSystemCredentialsUpdateWithoutPasswordApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Login */
  login: string;
};
export type PersonSystemTokenUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonSystemTokenUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  userLoginToken: UserLoginToken;
};
export type PersonSystemCredentialsDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Person' */ Person;
export type PersonSystemCredentialsDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonTagListApiResponse =
  /** status 200 OK result - array of type 'PersonTag' instances */ PersonTags;
export type PersonTagListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonTagUpsertApiResponse =
  /** status 200 OK result - single instance of type 'PersonTag' */ PersonTag;
export type PersonTagUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Tag name */
  tagName: string;
  /** Tag value */
  tagValue: string;
};
export type PersonTagInternalListApiResponse =
  /** status 200 OK result - array of type 'PersonTag' instances */ PersonTags;
export type PersonTagInternalListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonTagSystemListApiResponse =
  /** status 200 OK result - array of type 'PersonTag' instances */ PersonTags;
export type PersonTagSystemListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
};
export type PersonTagSystemUpsertApiResponse =
  /** status 200 OK result - single instance of type 'PersonTag' */ PersonTag;
export type PersonTagSystemUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Sid */
  sid: string;
  /** Tag name */
  tagName: string;
  /** Tag value */
  tagValue: string;
};
export type RelationDestinationListApiResponse =
  /** status 200 OK result - array of type 'RelationDestination' instances */ RelationDestinations;
export type RelationDestinationListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationDestinationListInstallationApiResponse =
  /** status 200 OK result - array of type 'RelationDestination' instances */ RelationDestinations;
export type RelationDestinationListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type RelationDestinationCreateForInstallationAllApiResponse =
  /** status 200 OK result - single instance of type 'RelationDestination' */ RelationDestination;
export type RelationDestinationCreateForInstallationAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Relation installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Address installation id */
  forInstallationId: number;
};
export type RelationDestinationDeleteForInstallationApiResponse =
  /** status 200 OK result - single instance of type 'RelationDestination' */ RelationDestination;
export type RelationDestinationDeleteForInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Relation installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Address installation id */
  forInstallationId: number;
  /** Address */
  address: string;
};
export type RelationDestinationCreateAllApiResponse =
  /** status 200 OK result - single instance of type 'RelationDestination' */ RelationDestination;
export type RelationDestinationCreateAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationDestinationDeleteApiResponse =
  /** status 200 OK result - single instance of type 'RelationDestination' */ RelationDestination;
export type RelationDestinationDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Address */
  address: string;
};
export type RelationDestinationSetAllDestinationsApiResponse =
  /** status 200 OK result - array of type 'RelationDestination' instances */ RelationDestinations;
export type RelationDestinationSetAllDestinationsApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  relationDestinationsJsons: RelationDestinationsJsons;
};
export type RelationDestinationSyncOriginApiResponse =
  /** status 200 OK result - array of type 'RelationDestination' instances */ RelationDestinations;
export type RelationDestinationSyncOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
  relationDestinations: RelationDestinations;
};
export type RelationDestinationSyncListOriginApiResponse =
  /** status 200 OK result - array of type 'RelationDestination' instances */ RelationDestinations;
export type RelationDestinationSyncListOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
};
export type RelationFileListApiResponse =
  /** status 200 OK result - array of type 'RelationFile' instances */ RelationFiles;
export type RelationFileListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationFileCreateUserFileApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileCreateUserFileApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** File name */
  fileName: string;
  /** File relation playing order position */
  orderPosition: number;
  /** File can be played in public domain */
  isPublic: number;
  /** File size defined (not uploaded yet) */
  fileSize: number;
  /** Creator RFC3339 local time of file (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  fileUtc: string;
};
export type RelationFileAddFromSystemSetApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileAddFromSystemSetApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Type */
  type: "ForeSpeech" | "FinalSpeech" | "RepeatSpeech";
  /** File relation playing order position */
  orderPosition: number;
  /** File can be played in public domain */
  isPublic: number;
  /** Creator RFC3339 local time of file (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  fileUtc: string;
};
export type RelationFileUploadApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileUploadApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** File id */
  fileId: number;
  body: Blob;
};
export type RelationFileDeleteApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** File id */
  fileId: number;
};
export type RelationFileSetOrderAllFilesApiResponse =
  /** status 200 OK result - array of type 'RelationFile' instances */ RelationFiles;
export type RelationFileSetOrderAllFilesApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  filePositionJsons: FilePositionJsons;
};
export type RelationFileDownloadApiResponse = /** status 200 OK result */ Blob;
export type RelationFileDownloadApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** File id */
  fileId: number;
};
export type RelationFileSingleFileCopyApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileSingleFileCopyApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** File id */
  fileId: number;
  /** Order position */
  orderPosition: number;
};
export type RelationFileSyncFromSystemSetApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileSyncFromSystemSetApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Filename */
  filename: string;
  /** Duration seconds */
  durationSeconds?: number | null;
  /** File relation playing order position */
  orderPosition: number;
  /** File can be played in public domain */
  isPublic: number;
  /** Creator RFC3339 local time of file (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  fileUtc: string;
  /** State uid content */
  stateUidContent: string;
};
export type RelationFileSyncOriginApiResponse =
  /** status 200 OK result - array of type 'RelationFile' instances */ RelationFiles;
export type RelationFileSyncOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
  relationFiles: RelationFiles;
};
export type RelationFileSyncListOriginApiResponse =
  /** status 200 OK result - array of type 'RelationFile' instances */ RelationFiles;
export type RelationFileSyncListOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
};
export type RelationFileTextListApiResponse =
  /** status 200 OK result - single instance of type 'RelationFileText' */ RelationFileText;
export type RelationFileTextListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Id */
  id: number;
};
export type RelationFileSetUserFileTextApiResponse =
  /** status 200 OK result - single instance of type 'RelationFileText' */ RelationFileText;
export type RelationFileSetUserFileTextApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Id */
  id: number;
  /** Speech text */
  speechText?: string | null;
};
export type RelationFileSetFileIsPublicApiResponse =
  /** status 200 OK result - single instance of type 'RelationFileText' */ RelationFileText;
export type RelationFileSetFileIsPublicApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Id */
  id: number;
  /** Is public */
  isPublic: number;
};
export type RelationFileSyncStateUidTextApiResponse =
  /** status 200 OK result - single instance of type 'RelationFile' */ RelationFile;
export type RelationFileSyncStateUidTextApiArg = {
  /** Session id */
  sessionId: string;
  /** Relation id */
  relationId: number;
  /** State uid content */
  stateUidContent: string;
  /** State uid text */
  stateUidText?: string | null;
};
export type RelationPlanDestinationListApiResponse =
  /** status 200 OK result - array of type 'PlanDestination' instances */ PlanDestinations;
export type RelationPlanDestinationListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
};
export type RelationPlanDestinationListInstallationApiResponse =
  /** status 200 OK result - array of type 'PlanDestination' instances */ PlanDestinations;
export type RelationPlanDestinationListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type RelationPlanDestinationSetAllApiResponse =
  /** status 200 OK result - array of type 'PlanDestination' instances */ PlanDestinations;
export type RelationPlanDestinationSetAllApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
  planDestinationsJsons: PlanDestinationsJsons;
};
export type RelationPlanDestinationSyncOriginApiResponse =
  /** status 200 OK result - array of type 'PlanDestination' instances */ PlanDestinations;
export type RelationPlanDestinationSyncOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
  planDestinations: PlanDestinations;
};
export type RelationPlanDestinationSyncListOriginApiResponse =
  /** status 200 OK result - array of type 'PlanDestination' instances */ PlanDestinations;
export type RelationPlanDestinationSyncListOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
};
export type RelationPlanPublicApiResponse =
  /** status 200 OK result - array of type 'RelationPlansPublic' instances */ RelationPlansPublics;
export type RelationPlanPublicApiArg = {
  /** Session id */
  sessionId: string;
};
export type RelationPlanStateListInstallationApiResponse =
  /** status 200 OK result - array of type 'RelationPlanState' instances */ RelationPlanStates;
export type RelationPlanStateListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type RelationPlanStateSyncUpsertApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlanState' */ RelationPlanState;
export type RelationPlanStateSyncUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Plan id */
  planId: number;
  /** State installation id */
  stateInstallationId: number;
  /** State code */
  stateCode: string;
  /** State text */
  stateText: string;
  /** Playing start RFC3339 local time (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  stateUtc: string;
  /** Is state canceled */
  isStateCanceled?: number | null;
  /** Is state downloading */
  isStateDownloading?: number | null;
  /** Is state ready */
  isStateReady?: number | null;
  /** Is state playing */
  isStatePlaying?: number | null;
  /** Is state error */
  isStateError?: number | null;
  /** Is state done */
  isStateDone?: number | null;
};
export type RelationPlanStateSyncDeleteApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlanState' */ RelationPlanState;
export type RelationPlanStateSyncDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** State installation id */
  stateInstallationId: number;
  /** Plan id */
  planId: number;
  /** State utc */
  stateUtc: string;
};
export type RelationPlanStateSyncStateInstallationApiResponse =
  /** status 200 OK result - array of type 'RelationPlanState' instances */ RelationPlanStates;
export type RelationPlanStateSyncStateInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** State installation id */
  stateInstallationId: number;
  /** Origin installation name */
  originInstallationName: string;
  relationPlanStates: RelationPlanStates;
};
export type RelationPlanStateSyncListOriginApiResponse =
  /** status 200 OK result - array of type 'RelationPlanState' instances */ RelationPlanStates;
export type RelationPlanStateSyncListOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
};
export type RelationPlanListInstallationApiResponse =
  /** status 200 OK result - array of type 'RelationPlan' instances */ RelationPlans;
export type RelationPlanListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type RelationPlanListApiResponse =
  /** status 200 OK result - array of type 'RelationPlan' instances */ RelationPlans;
export type RelationPlanListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationPlanCreateApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Name */
  name: string;
  /** Playing start RFC3339 local time (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  startUtc: string;
  /** Max delay in seconds */
  maxDelayInSeconds?: number;
};
export type RelationPlanUpdateApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanUpdateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
  /** Playing start RFC3339 local time (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  startUtc: string;
  /** Max delay in seconds */
  maxDelayInSeconds?: number;
};
export type RelationPlanDeleteApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
};
export type RelationPlanSetCompleteApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanSetCompleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
  /** Is complete */
  isComplete: number;
};
export type RelationPlanCompletationInfoApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlanCompletationInfo' */ RelationPlanCompletationInfo;
export type RelationPlanCompletationInfoApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
};
export type RelationPlanSyncOriginApiResponse =
  /** status 200 OK result - array of type 'RelationPlan' instances */ RelationPlans;
export type RelationPlanSyncOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
  relationPlans: RelationPlans;
};
export type RelationPlanSyncListOriginApiResponse =
  /** status 200 OK result - array of type 'RelationPlan' instances */ RelationPlans;
export type RelationPlanSyncListOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
};
export type RelationPlanSyncStateUpdateDestApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanSyncStateUpdateDestApiArg = {
  /** Session id */
  sessionId: string;
  /** Uid */
  uid: string;
  /** State uid dest */
  stateUidDest: string;
};
export type RelationPlanSyncStateUpdateStateApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanSyncStateUpdateStateApiArg = {
  /** Session id */
  sessionId: string;
  /** Uid */
  uid: string;
  /** State uid state */
  stateUidState: string;
  /** Old state uid state */
  oldStateUidState: string;
};
export type RelationPlanSyncListAllApiResponse =
  /** status 200 OK result - array of type 'RelationPlan' instances */ RelationPlans;
export type RelationPlanSyncListAllApiArg = {
  /** Session id */
  sessionId: string;
};
export type RelationPlanSetDeleteRequestApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanSetDeleteRequestApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
};
export type RelationPlanSetStopRequestApiResponse =
  /** status 200 OK result - single instance of type 'RelationPlan' */ RelationPlan;
export type RelationPlanSetStopRequestApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Plan id */
  planId: number;
};
export type RelationPublicFileListApiResponse =
  /** status 200 OK result - array of type 'RelationFile' instances */ RelationFiles;
export type RelationPublicFileListApiArg = {
  /** Session id */
  sessionId: string;
  /** Relation id */
  relationId: number;
};
export type RelationPublicFileTextListApiResponse =
  /** status 200 OK result - array of type 'RelationFileText' instances */ RelationFileTexts;
export type RelationPublicFileTextListApiArg = {
  /** Session id */
  sessionId: string;
  /** Relation id */
  relationId: number;
  /** Ids */
  ids: string;
};
export type RelationPublicFileDownloadApiResponse =
  /** status 200 OK result */ Blob;
export type RelationPublicFileDownloadApiArg = {
  /** Session id */
  sessionId: string;
  /** Relation id */
  relationId: number;
  /** File id */
  fileId: number;
};
export type RelationListApiResponse =
  /** status 200 OK result - array of type 'Relation' instances */ Relations;
export type RelationListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type RelationCreateApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationCreateApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
};
export type RelationCopyRelationFilesApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationCopyRelationFilesApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Source relation id */
  sourceRelationId: number;
};
export type RelationDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationCompletationInfoApiResponse =
  /** status 200 OK result - single instance of type 'RelationCompletationInfo' */ RelationCompletationInfo;
export type RelationCompletationInfoApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationSetCompleteApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationSetCompleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Is complete */
  isComplete: number;
};
export type RelationSetSmsIdentApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationSetSmsIdentApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
  /** Sms ident */
  smsIdent: string;
};
export type RelationSetUploadRequestApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationSetUploadRequestApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Relation id */
  relationId: number;
};
export type RelationSyncSetUploadRequestApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationSyncSetUploadRequestApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
  /** Origin installation name */
  originInstallationName: string;
  /** Upload request */
  uploadRequest: number;
};
export type RelationSyncUpdateStateUidApiResponse =
  /** status 200 OK result - single instance of type 'Relation' */ Relation;
export type RelationSyncUpdateStateUidApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
  /** State uid dest */
  stateUidDest: string;
  /** State uid file */
  stateUidFile: string;
  /** Origin installation name */
  originInstallationName: string;
};
export type RelationSyncOriginApiResponse =
  /** status 200 OK result - array of type 'Relation' instances */ Relations;
export type RelationSyncOriginApiArg = {
  /** Session id */
  sessionId: string;
  /** Origin installation name */
  originInstallationName: string;
  relations: Relations;
};
export type RelationSyncListAllApiResponse =
  /** status 200 OK result - array of type 'Relation' instances */ Relations;
export type RelationSyncListAllApiArg = {
  /** Session id */
  sessionId: string;
};
export type ServerOriginNameApiResponse =
  /** status 200 OK result - single instance of type 'ServerName' */ ServerName;
export type ServerOriginNameApiArg = {
  /** Session id */
  sessionId: string;
};
export type SessionCreateApiResponse =
  /** status 200 OK result - single instance of type 'Session' */ Session;
export type SessionCreateApiArg = void;
export type SessionDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Session' */ Session;
export type SessionDeleteApiArg = {
  /** Session id */
  sessionId: string;
};
export type SessionPermissionListApiResponse =
  /** status 200 OK result - array of type 'SessionPermission' instances */ SessionPermissions;
export type SessionPermissionListApiArg = {
  /** Session id */
  sessionId: string;
};
export type SessionRefreshApiResponse =
  /** status 200 OK result - single instance of type 'Session' */ Session;
export type SessionRefreshApiArg = {
  /** Session id */
  sessionId: string;
};
export type SourceListApiResponse =
  /** status 200 OK result - array of type 'Source' instances */ Sources;
export type SourceListApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SourceUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Source' */ Source;
export type SourceUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
  /** Type */
  type: string;
  /** Settings */
  settings: string;
};
export type SourceSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'Source' instances */ Sources;
export type SourceSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  sources: Sources;
};
export type SourceDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Source' */ Source;
export type SourceDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
};
export type SourceSyncListAllApiResponse =
  /** status 200 OK result - array of type 'Source' instances */ Sources;
export type SourceSyncListAllApiArg = {
  /** Session id */
  sessionId: string;
};
export type SourceSyncUpdateStateUidApiResponse =
  /** status 200 OK result - single instance of type 'Source' */ Source;
export type SourceSyncUpdateStateUidApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  /** Name */
  name: string;
  /** State uid */
  stateUid: string;
};
export type SourceSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'Source' instances */ Sources;
export type SourceSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SourceTagsListApiResponse =
  /** status 200 OK result - array of type 'SourceTag' instances */ SourceTags;
export type SourceTagsListApiArg = {
  /** Session id */
  sessionId: string;
  /** Source id */
  sourceId: number;
};
export type SourceTagsSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'SourceTag' instances */ SourceTags;
export type SourceTagsSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SourceTagsUpsertApiResponse =
  /** status 200 OK result - single instance of type 'SourceTag' */ SourceTag;
export type SourceTagsUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Source id */
  sourceId: number;
  /** Tag name */
  tagName: string;
  /** Tag type */
  tagType: string;
  /** Tag value */
  tagValue?: string | null;
};
export type SourceTagsSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'SourceTag' instances */ SourceTags;
export type SourceTagsSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  sourceTags: SourceTags;
};
export type SupplierListApiResponse =
  /** status 200 OK result - array of type 'Supplier' instances */ Suppliers;
export type SupplierListApiArg = {
  /** Session id */
  sessionId: string;
  /** Source id */
  sourceId: number;
};
export type SupplierSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'Supplier' instances */ Suppliers;
export type SupplierSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SupplierSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'Supplier' instances */ Suppliers;
export type SupplierSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  suppliers: Suppliers;
};
export type SupplierUpsertApiResponse =
  /** status 200 OK result - single instance of type 'Supplier' */ Supplier;
export type SupplierUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Source id */
  sourceId: number;
  /** Name */
  name: string;
  /** Description */
  description?: string | null;
};
export type SupplierDeleteApiResponse =
  /** status 200 OK result - single instance of type 'Supplier' */ Supplier;
export type SupplierDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Source id */
  sourceId: number;
  /** Name */
  name: string;
};
export type SupplierUnitsListApiResponse =
  /** status 200 OK result - array of type 'SupplierUnit' instances */ SupplierUnits;
export type SupplierUnitsListApiArg = {
  /** Session id */
  sessionId: string;
  /** Supplier id */
  supplierId: number;
};
export type SupplierUnitsListInstallationApiResponse =
  /** status 200 OK result - array of type 'SupplierUnit' instances */ SupplierUnits;
export type SupplierUnitsListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SupplierUnitsSyncListInstallationApiResponse =
  /** status 200 OK result - array of type 'SupplierUnit' instances */ SupplierUnits;
export type SupplierUnitsSyncListInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
};
export type SupplierUnitsSyncInstallationApiResponse =
  /** status 200 OK result - array of type 'SupplierUnit' instances */ SupplierUnits;
export type SupplierUnitsSyncInstallationApiArg = {
  /** Session id */
  sessionId: string;
  /** Installation id */
  installationId: number;
  supplierUnits: SupplierUnits;
};
export type SupplierUnitsUpsertApiResponse =
  /** status 200 OK result - single instance of type 'SupplierUnit' */ SupplierUnit;
export type SupplierUnitsUpsertApiArg = {
  /** Session id */
  sessionId: string;
  /** Supplier id */
  supplierId: number;
  /** Type */
  type: string;
  /** Name */
  name: string;
  /** Order position */
  orderPosition: number;
  /** Unit */
  unit: string;
  /** Precision */
  precision: number;
  /** Description */
  description: string;
  /** Format */
  format?: string | null;
  /** For service */
  forService: number;
};
export type SupplierUnitsDeleteApiResponse =
  /** status 200 OK result - single instance of type 'SupplierUnit' */ SupplierUnit;
export type SupplierUnitsDeleteApiArg = {
  /** Session id */
  sessionId: string;
  /** Supplier id */
  supplierId: number;
  /** Name */
  name: string;
};
export type AppPermission = {
  installation_id: number;
  code: string;
  text: string;
  description: string | null;
  custom: number;
};
export type AppPermissions = AppPermission[];
export type Error = {
  error: {
    message: string;
    data?: string | null;
  };
};
export type Errors = Error[];
export type StorageFolderItem = {
  is_folder: number;
  item_name: string;
  item_utc: string;
  size: number | null;
};
export type StorageFolderItems = StorageFolderItem[];
export type StorageUsage = {
  used: number;
  limit: number;
  unit: string;
};
export type UserLoggedIn = {
  installation_id: number;
  installation_name: string;
  sid: string;
  first_name: string;
  surname: string;
  description: string;
  type: string;
  password_set_enabled: number;
  password_set_required: number;
};
export type UserLoginPassword = {
  password: string;
};
export type UserLoginToken = {
  key: string;
};
export type PlanPublicInstallation = {
  installation_name: string;
};
export type DeviceDestination = {
  id: number;
  name: string;
  description: string;
  address: string;
  backinfo_enabled: number | null;
  gps_lat: number | null;
  gps_lng: number | null;
  locality_id: number;
  locality_name: string;
  locality_description: string;
  locality_address: string;
  installation_id: number;
  installation_name: string;
  device_order_position: number;
};
export type DeviceDestinations = DeviceDestination[];
export type DeviceImportSettingsList = {
  id: number;
  device_installation_id: number;
  device_name: string;
  device_installation_name: string;
  installation_id: number;
  installation_name: string;
};
export type DeviceImportSettingsLists = DeviceImportSettingsList[];
export type DeviceImportIntegration = {
  id: number;
  device_installation_id: number;
  device_name: string;
  device_installation_name: string;
  installation_id: number;
  installation_name: string;
  device_description: string;
  locality_name: string;
  locality_id: number;
  gps_lat: number;
  gps_lng: number;
  supplier_id: number;
  url_web: string;
  url_data: string;
  unit_type: string;
};
export type DeviceImportIntegrations = DeviceImportIntegration[];
export type DeviceImportSettings = {
  installation_id: number;
  import_device_id: number;
};
export type DeviceImportSettingss = DeviceImportSettings[];
export type DeviceImportSettingsInstallation = {
  installation_id: number;
  installation_name: string;
};
export type DeviceImportSettingsInstallations =
  DeviceImportSettingsInstallation[];
export type DeviceImport = {
  id: number;
  locality_name: string;
  locality_id: number;
  device_name: string;
  unit_type: string;
  gps_lat: number | null;
  gps_lng: number | null;
  url_web: string;
  url_data: string;
  rule_name: string;
  installation_id: number;
};
export type DeviceImports = DeviceImport[];
export type DeviceImporter = {
  device_id: number;
  unit_type: string;
  device_last_value: number | null;
  device_last_measured: string | null;
  device_name: string;
  supplier_name: string;
  source_name: string;
  installation_id: number;
  installation_name: string;
  url_values: string;
};
export type DeviceImporters = DeviceImporter[];
export type Device = {
  id: number;
  locality_id: number;
  name: string;
  description: string | null;
  gps_lat: number | null;
  gps_lng: number | null;
  order_position: number | null;
};
export type Devices = Device[];
export type DeviceParents = {
  device_id: number;
  device_name: string;
  locality_id: number;
  locality_name: string;
  supplier_id: number;
  supplier_name: string;
  source_id: number;
  source_name: string;
};
export type DeviceRecord = {
  device_id: number;
  unit_type: string;
  time_utc: string;
  value: number;
  is_error: number;
};
export type DeviceRecords = DeviceRecord[];
export type DeviceRecordLast = {
  device_id: number;
  unit_type: string;
  time_utc: string | null;
  value: number | null;
  is_error: number | null;
};
export type DeviceRecordLasts = DeviceRecordLast[];
export type DeviceTag = {
  device_id: number;
  tag_name: string;
  tag_value: string | null;
};
export type DeviceTags = DeviceTag[];
export type DeviceUnitLevelRuleItem = {
  rule_id: number | null;
  VALUE: number;
  name: string;
  common_state: number;
};
export type DeviceUnitLevelRuleItems = DeviceUnitLevelRuleItem[];
export type DeviceUnitLevelRule = {
  rule_id: number;
  public: number;
  sort_up: number;
  name: string;
  device_id: number;
  unit_type: string;
};
export type DeviceUnitLevelRules = DeviceUnitLevelRule[];
export type DeviceUnit = {
  id: number;
  device_id: number;
  locality_id: number;
  supplier_id: number;
  unit_type: string;
};
export type InstallationDeviceId = {
  installation_id: number;
  supplier_id: number;
  locality_id: number;
  device_id: number;
};
export type InstallationDeviceIds = InstallationDeviceId[];
export type DeviceUnits = DeviceUnit[];
export type InstallationCommandRequest = {
  session_id: string;
  request_token: string;
  command: string;
  command_parameter: string;
  origin_server: string;
  user_name: string;
};
export type InstallationCommandReply = {
  session_id: string;
  request_token: string;
  command: string;
  command_parameter: string;
  origin_server: string;
  result_ok: number;
  result_message: string;
};
export type Installation = {
  id: number;
  name: string;
  state_uid_local: string | null;
  state_uid_remote: string | null;
  installation_has_imp_dev: number;
};
export type Installations = Installation[];
export type InstallationImported = {
  installation_id: number;
  import_installation_id: number;
};
export type InstallationImporteds = InstallationImported[];
export type InstallationTag = {
  installation_id: number;
  tag_name: string;
  tag_value: string | null;
  config: string;
  section: string;
};
export type InstallationTags = InstallationTag[];
export type InstallationTagSectionItemNew = {
  tag_name: string;
  tag_value: string | null;
};
export type InstallationTagSectionItemNews = InstallationTagSectionItemNew[];
export type InstallationRegistration = {
  "access-key": string;
};
export type Locality = {
  id: number;
  supplier_id: number;
  address: string | null;
  name: string;
  description: string | null;
  kind: string | null;
  order_position: number | null;
  installation_id: number;
};
export type Localitys = Locality[];
export type MqttBridgeAuth = {
  sid: string;
};
export type MqttWebSocketOneTimePwd = {
  password: string;
};
export type MqttWebSocketTopic = {
  topic: string;
};
export type MqttWebSocketTopics = MqttWebSocketTopic[];
export type Permission = {
  installation_id: number;
  sid_group: string;
  code: string;
  for_installation_id: number;
  type: string;
  for_installation_name: string;
};
export type Permissions = Permission[];
export type SidPermission = {
  installation_id: number;
  code: string;
};
export type SidPermissions = SidPermission[];
export type PermissionUser = {
  installation_id: number;
  sid: string;
  type: string;
  code: string;
  for_installation_id: number;
  for_installation_name: string;
};
export type PermissionUsers = PermissionUser[];
export type PermissionGroup = {
  installation_id: number;
  sid: string;
  name: string;
};
export type PermissionGroups = PermissionGroup[];
export type PermissionLocality = {
  sid_group: string;
  locality_install_id: number;
  locality_name: string;
  locality_id: number;
  sid_installation_id: number;
  type: string;
};
export type PermissionLocalitys = PermissionLocality[];
export type PermissionLocalityDest = {
  installation_id: number;
  sid: string;
  type: string;
  locality_id: number;
  for_installation_id: number;
};
export type PermissionLocalityDests = PermissionLocalityDest[];
export type PermissionUserLocality = {
  sid_installation_id: number;
  locality_id: number;
  locality_install_id: number;
  locality_name: string;
  enabled: number;
};
export type PermissionUserLocalitys = PermissionUserLocality[];
export type PermissionTreeItem = {
  installation_id: number;
  sid_user: string;
  sid_group: string;
  sid_group_name: string;
  sid_group_type: string;
};
export type PermissionTreeItems = PermissionTreeItem[];
export type PermissionGroupKey = {
  installation_id: number;
  sid: string;
};
export type PermissionGroupKeys = PermissionGroupKey[];
export type Person = {
  installation_id: number;
  sid: string;
  first_name: string;
  surname: string;
  description: string;
  is_user: number;
};
export type Persons = Person[];
export type PersonCredentials = {
  installation_id: number;
  sid: string;
  login: string;
  is_user: number;
  has_password_login: number;
  has_token_key: number;
  login_enabled: number;
  password_set_enabled: number;
  password_set_required: number;
};
export type PersonCredentialss = PersonCredentials[];
export type UserLoginChangePassword = {
  old_password: string;
  new_password: string;
};
export type PersonTag = {
  installation_id: number;
  sid: string;
  tag_name: string;
  tag_value: string;
};
export type PersonTags = PersonTag[];
export type RelationDestination = {
  relation_id: number;
  installation_id: number;
  address: string;
};
export type RelationDestinations = RelationDestination[];
export type RelationDestinationsJson = {
  installation_id: number;
  address: string;
};
export type RelationDestinationsJsons = RelationDestinationsJson[];
export type RelationFile = {
  id: number;
  relation_id: number;
  file_name: string;
  is_public: number;
  order_position: number;
  file_size: number;
  content_size: number | null;
  duration_seconds: number | null;
  is_complete: number;
  /** Creator RFC3339 local time of file (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  file_utc: string;
  state_uid_content: string;
  state_uid_text: string | null;
  has_speech_text: number;
};
export type RelationFiles = RelationFile[];
export type FilePositionJson = {
  id: number;
  position: number;
};
export type FilePositionJsons = FilePositionJson[];
export type RelationFileText = {
  id: number;
  relation_id: number;
  file_name: string;
  is_public: number;
  speech_text: string | null;
};
export type PlanDestination = {
  plan_id: number;
  installation_id: number;
  address: string;
};
export type PlanDestinations = PlanDestination[];
export type PlanDestinationsJson = {
  installation_id: number;
  address: string;
};
export type PlanDestinationsJsons = PlanDestinationsJson[];
export type RelationPlansPublic = {
  plan_id: number;
  relation_id: number;
  plan_name: string;
  installation_name: string;
  has_speech_text: number;
  start_utc: string;
};
export type RelationPlansPublics = RelationPlansPublic[];
export type RelationPlanState = {
  installation_id: number;
  relation_id: number;
  plan_id: number;
  plan_uid: string;
  plan_uid_state: string;
  is_plan_complete: number;
  state_installation_id: number;
  state_installation_name: string;
  state_code: string;
  state_text: string;
  state_utc: string;
  is_state_canceled: number | null;
  is_state_downloading: number | null;
  is_state_ready: number | null;
  is_state_playing: number | null;
  is_state_error: number | null;
  is_state_done: number | null;
};
export type RelationPlanStates = RelationPlanState[];
export type RelationPlan = {
  installation_id: number;
  id: number;
  relation_id: number;
  origin_installation_name: string;
  name: string;
  is_complete: number;
  relation_is_complete: number;
  uid: string;
  state_uid_dest: string;
  state_uid_state: string;
  /** Playing start RFC3339 local time (yyyy-mm-dd hh:MM:ss+(Z|[+-]hh[:mm]) */
  start_utc: string;
  /** Max allowed delay between required start and real start (download delay) */
  max_delay_in_seconds: number;
  delete_request: number;
  stop_request: number;
  is_history: number;
};
export type RelationPlans = RelationPlan[];
export type RelationPlanCompletationInfo = {
  plan_id: number;
  plan_name: string;
  relation_id: number;
  installation_id: number;
  is_complete: number;
  is_completable: number;
  is_rel_dest_pres: number;
  is_plan_dest_pres: number;
};
export type RelationFileTexts = RelationFileText[];
export type Relation = {
  id: number;
  installation_id: number;
  installation_name: string;
  name: string;
  is_complete: number;
  state_uid_dest: string;
  state_uid_file: string;
  duration_seconds: number | null;
  files_without_duration: number;
  origin_installation_name: string;
  upload_request: number;
  upload_available: number;
  is_foreign_address_installation: number;
  sms_ident: string | null;
};
export type Relations = Relation[];
export type RelationCompletationInfo = {
  relation_id: number;
  installation_id: number;
  relation_name: string;
  is_complete: number;
  is_completable: number;
  is_file_present: number;
  is_files_complete: number;
};
export type ServerName = {
  name: string;
};
export type Session = {
  sid: string;
  valid_to: string;
  current_timestamp: string;
};
export type SessionPermission = {
  installation_id: number;
  for_installation_id: number;
  access_name: string;
  access_value: string;
};
export type SessionPermissions = SessionPermission[];
export type Source = {
  id: number;
  installation_id: number;
  name: string;
  type: string;
  settings: string;
  state_uid: string;
};
export type Sources = Source[];
export type SourceTag = {
  source_id: number;
  tag_name: string;
  tag_type: string;
  tag_value: string | null;
};
export type SourceTags = SourceTag[];
export type Supplier = {
  id: number;
  source_id: number;
  name: string;
  description: string | null;
};
export type Suppliers = Supplier[];
export type SupplierUnit = {
  supplier_id: number;
  type: string;
  name: string;
  order_position: number;
  unit: string;
  precision: number;
  description: string;
  format: string | null;
  for_service: number;
};
export type SupplierUnits = SupplierUnit[];
