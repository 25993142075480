import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import ReactDOMServer from 'react-dom/server'

import type { RootState } from '../../store'

interface IInitialState {
    type: string | null;
    title: string | null;
    message: JSX.Element |string | null | {html: string} ;
    isShown: boolean;
    onHide?: ((data:any) => void) | (() => void);
    onYes?: ((data:any) => void) | (() => void);
    callbackData?: any;
}

interface INotificationParams {
    title: string | null;
    message: JSX.Element |string | null;
    onHide?: ((data:any) => void) | (() => void);
    callbackData?: any;
}
interface IConfirmationParams {
    title: string | null;
    message: JSX.Element | string | null;
    onHide?: ((data:any) => void) | (() => void);
    onYes?: ((data:any) => void) | (() => void);
    callbackData?: any;
}

// reducers
const initialState: IInitialState = {
    type: null,
    title: null,
    message: null,
    isShown: false
}

const notificationsSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        reset: () => initialState,
        setErrorMessage(state, action: PayloadAction<string>) {
            state.type = 'error'
            state.title = 'Chyba'
            state.message = action.payload 
            state.isShown = true
        },
        setNotificationMessage(state, action: PayloadAction<INotificationParams>) {
            state.type = 'notification'
            state.title = action.payload.title
            state.message =(action.payload.message as JSX.Element) ? {html: ReactDOMServer.renderToString(action.payload.message as JSX.Element)} : action.payload.message
            state.onHide = action.payload.onHide
            state.callbackData = action.payload.callbackData
            state.isShown = true
        },
        setConfirmationMessage(state, action: PayloadAction<IConfirmationParams>) {
            state.type = 'confirmation'
            state.title = action.payload.title
            state.message = (action.payload.message as JSX.Element) ? {html: ReactDOMServer.renderToString(action.payload.message as JSX.Element)} : action.payload.message
            state.onYes = action.payload.onYes
            state.onHide = action.payload.onHide
            state.callbackData = action.payload.callbackData
            state.isShown = true
        },
        setIsShown(state, action) {
            state.isShown = action.payload
        },
    }
})

export const { 
    reset: resetMessage, 
    setErrorMessage, 
    setNotificationMessage,
    setConfirmationMessage,
    setIsShown: setIsMessageShown,
} = notificationsSlice.actions

export default notificationsSlice.reducer

// selectors
export const selectType = (state: RootState) => state.notification.type
export const selectTitle = (state: RootState) => state.notification.title
export const selectMessage = (state: RootState) => state.notification.message
export const selectIsShown = (state: RootState) => state.notification.isShown
export const selectOnHide = (state: RootState) => state.notification.onHide
export const selectOnYes = (state: RootState) => state.notification.onYes
export const selectCallbackData = (state: RootState) => state.notification.callbackData
