import { citywebApi } from "../../openapi/citywebApi";
import { processError } from "../../utils/errors";
import citywebApiTags from "../../openapi/citywebApiTags";


export const plansPublicApiSlice = citywebApi.enhanceEndpoints({
  addTagTypes: [citywebApiTags.plans, citywebApiTags.planDestinations, citywebApiTags.relationFiles],
  endpoints: {
    authenticationPublicPlanToken: 
    {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
              await queryFulfilled                    
          } catch(error) {
              processError(error, dispatch, 'Neplatná Licence')
          }
      },
    },
    relationPlanPublic: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
              await queryFulfilled
          } catch(error) {
              processError(error, dispatch, 'Nepodařilo se načíst veřejné plány')
          }
      },
  },
  relationPublicFileList: {
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
            await queryFulfilled
        } catch(error) {
            processError(error, dispatch, 'Nepodařilo se načíst veřejné soubory')
        }
    },
  },
  relationPublicFileTextList: {
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
            await queryFulfilled
        } catch(error) {
            processError(error, dispatch, 'Nepodařilo se načíst text veřejných souborů')
        }
    },
  },
  relationPublicFileDownload: {
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
            await queryFulfilled
        } catch(error) {
            processError(error, dispatch, 'Nepodařilo se načíst veřejný soubor')
        }
    },
  },
}
});

export const {
  useAuthenticationPublicPlanTokenMutation,
  useRelationPlanPublicQuery,
  useRelationPublicFileListQuery,
  useRelationPublicFileTextListQuery,
  useRelationPublicFileDownloadQuery,
} = plansPublicApiSlice;
