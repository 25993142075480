const citywebApiTags = {
sessionPermissions: 'SessionPermissions',
relations: 'Relations',
relationFiles: 'RelationFiles',
relationDestinations: 'RelationDestinations',
destinations: 'Destinations',
plans: 'Plans',
planDestinations: 'PlanDestinations',
planStates: 'PlanStates',
persons: 'Persons',
systemPersons: 'SystemPersons',
personTag: 'PersonTag',
personCredentials: 'PersonCredentials',
systemPersonCredentials: 'SystemPersonCredentials',
sidPermissions: 'SidPermissions',
sidPermissionGroups: 'SidPermissionGroups',
appPermissions: 'AppPermissions',
appPermissionsSystem: 'AppPermissionsSystem',
sidPermissionTree: 'SidPermissionTree',
sidPermissionsSystem: 'SidPermissionsSystem',
sidPermissionGroupsSystem: 'SidPermissionGroupsSystem',
sidPermissionTreeSystem: 'SidPermissionTreeSystem',
permissionUser: 'PermissionUser',
mqttApi: 'MqttApi',
devicesImport: 'DevicesImport',
devicesImportSettings: 'DevicesImportSettings',
localityListInstallations: 'LocalityListInstallations',
permissionLocalityList: 'PermissionLocalityList',
permissionLocalitySystemList: 'PermissionLocalitySystemList',
devicesIntegrationList: 'DevicesIntegrationList',
installationTag: 'InstallationTag',
};
export default citywebApiTags;
