
import type { Session } from "../openapi/citywebApi"

export function setTokensLocal(token: Session | null) {
    
    if (token && token.hasOwnProperty('sid')) {
        token = {...token, sid: token.sid.trim()} 
        sessionStorage.setItem('access_token', JSON.stringify(token));
    }
    else {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('access_token');
    }
}
