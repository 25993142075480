import React from 'react';
import { useSelector } from 'react-redux'
import { StylesConfig, Props, GroupBase } from 'react-select'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'

import { useSessionCreateMutation, useLazyInstallationsGuessQuery } from '../features/session/sessionApiSlice'
import { selectSessionId } from '../features/session/sessionSlice'
import { useEffect } from 'react'



export type OptionType = {
    value: string;
    label: string;
}

type IsMulti = false

interface AsyncSelectProps extends Props<OptionType, IsMulti, GroupBase<OptionType>> {
    onSuccess?: () => void,
    onError?: (msg: string) => void,
    isValid?: boolean,
    disabled?: boolean
}

const AsyncLocalitySelect = ({onError, onSuccess, ...props}: AsyncSelectProps) => {

    // store access
    const storedSessionId = useSelector(selectSessionId)
    
    const [fetchInstallations, installations,] = useLazyInstallationsGuessQuery()
    const [createSession, ] = useSessionCreateMutation()

    useEffect(() => {
        if(storedSessionId){
            try {
                fetchInstallations({ sessionId: storedSessionId})
                if (onSuccess) onSuccess();
            } catch (error) {}
        }
    }, [fetchInstallations, storedSessionId])
    
    const customStyles: StylesConfig<OptionType, IsMulti> = {
        container: (base: any) => ({
            ...base,
            textAlign: 'left',
            '& > div:first-of-type' : {
                boxShadow: 'none'
            },
            marginBottom: '1rem'
        })
    }

    const filterInstallations = async (inputValue: string, sessionId: string): Promise<OptionType[]> => {
            try {
                if (installations?.data && inputValue.trim() !== "") {
                    const removeDiacritics = (str: string) => {
                        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    };
    
                    const trimmedInput = removeDiacritics(inputValue.trim().toUpperCase());
    
                    const filteredInstallations = installations.data.filter((installation: any) => {
                        const name = removeDiacritics(installation.name.toUpperCase());
                        return name.includes(trimmedInput)
                    });
              
                    return filteredInstallations.map((installation: any) => ({
                      value: installation.id.toString(),
                      label: installation.name,
                    }));
            }
            } catch (error) {}
    
    return [];
    }

    const debouncedFilterInstallations = debounce(filterInstallations, 300, { leading: true })
    
    const promiseOptions = async (inputValue: string): Promise<OptionType[]> => {
        if (inputValue) {
                let sessionId = storedSessionId

                if (!sessionId) {
                    try {
                        const session = await createSession().unwrap()
                        return debouncedFilterInstallations(inputValue, session.sid ?? '')
                    }
                    catch (error) {}
                }
                else {
                    return debouncedFilterInstallations(inputValue, sessionId ?? '')
                }
        }
        return []
    }

    return (
        <AsyncSelect {...props}
            cacheOptions
            autoFocus
            loadOptions={promiseOptions}
            isDisabled={props.disabled}
            placeholder="Název obce nebo PSČ ..."
            loadingMessage={() => 'Nahrávám ...'}
            noOptionsMessage={() => 'Žádná volba'}
            styles={customStyles}
        />
    )
    
}

export default AsyncLocalitySelect
