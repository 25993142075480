import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Installation } from '../../openapi/citywebApi';

import type { RootState } from '../../store'
import moment from 'moment';
import { toServerFmt } from '../../utils/DateTime';
import { SelectedInstallation } from './sessionInterfaces';


const authtokens = (function () {
    const existingTokens = JSON.parse(sessionStorage.getItem("access_token") ?? '{}');
    const sid: string | null = (existingTokens && existingTokens.hasOwnProperty('sid')) ? existingTokens.sid : null;
    return { sid };
})();

// reducers
interface IInitialState {
    userName: string;
    sessionId: string | null;
    installationId: number;
    installationName: string;
    targetInstallation: Installation;
    activePage: string;
    isAuthenticated: boolean;
    isSystemUser: boolean;
    userSid: string
    passwordSetEnabled: number;
    passwordSetRequired: number;
    serverOriginName: string;
    sessionValidTime?: string | null;
    sessionValidTo?: string | null;
    selectedInstallation?: SelectedInstallation;
    googleApiKey: string;
}

const initialState: IInitialState = {
    userName: sessionStorage.getItem('user') || 'Host',
    sessionId: authtokens.sid,
    installationId: parseInt(localStorage.getItem('installation_id') ?? ''),
    installationName: localStorage.getItem('installation_name') || 'Neznámá lokalita',
    targetInstallation: JSON.parse(sessionStorage.getItem("targetInstallation")?? '{ "id": null, "name": "" }') ?? { id: NaN, name: '' },
    activePage: '/dashboard',
    isAuthenticated: false,
    isSystemUser: (localStorage.getItem('isSystemUser') || '') === 'true' ? true : false,
    userSid: sessionStorage.getItem('userSid') || '',
    passwordSetEnabled: parseInt(sessionStorage.getItem('passwordSetEnabled') ?? ''),
    passwordSetRequired: parseInt(sessionStorage.getItem('passwordSetRequired') ?? ''),
    serverOriginName: sessionStorage.getItem('serverOriginName') || 'Neznámá lokalita',
    sessionValidTime: sessionStorage.getItem('sessionValidTime') || null,
    sessionValidTo: sessionStorage.getItem('sessionValidTo') || null,
    selectedInstallation: JSON.parse(localStorage.getItem('selectedInstallation') ?? 'null') ?? null,
    googleApiKey: localStorage.getItem('googleApiKey') || ''
}
const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        resetSession(state) {
            state.userName = 'Host'
            state.sessionId = null
            state.installationId = parseInt(localStorage.getItem('installation_id') ?? '')
            state.installationName = localStorage.getItem('installation_name') || 'Neznámá lokalita'
            state.targetInstallation = { id: NaN, name: '', installation_has_imp_dev: 0, state_uid_local: '', state_uid_remote: '' }
            state.activePage = '/dashboard'
            state.isAuthenticated = false
            state.isSystemUser = false
            state.userSid = ''
            state.passwordSetEnabled = 0
            state.passwordSetRequired = 0
            state.serverOriginName = 'Neznámá lokalita'
            state.sessionValidTime = null
            state.sessionValidTo = null
            state.googleApiKey = localStorage.getItem('googleApiKey') || ''
            sessionStorage.setItem("targetInstallation", JSON.stringify(state.targetInstallation))
            sessionStorage.removeItem("targetInstallation");
            sessionStorage.removeItem("passwordSetRequired");
            sessionStorage.removeItem("passwordSetEnabled");
            sessionStorage.removeItem("userSid");
            sessionStorage.removeItem("isSystemUser");
            sessionStorage.removeItem("serverOriginName");
            sessionStorage.removeItem("sessionValidTime");
            sessionStorage.removeItem("sessionValidTo");
        },
        userNameChanged(state, action) {
            state.userName = action.payload
        },
        sessionIdChanged(state, action) {
            state.sessionId = action.payload
        },
        installationIdChanged(state, action) {
            state.installationId = action.payload
        },        
        installationNameChanged(state, action) {
            state.installationName = action.payload
        },
        targetInstallationChanged(state, action) {
            state.targetInstallation = action.payload
            sessionStorage.setItem("targetInstallation", JSON.stringify(state.targetInstallation))
        },
        activePageChanged(state, action) {
            state.activePage = action.payload
        },
        isAuthenticatedChanged(state, action) {
            state.isAuthenticated = action.payload
        },
        isSystemUserChanged(state, action) {
            state.isSystemUser = action.payload
            sessionStorage.setItem("isSystemUser",state.isSystemUser.toString())
        },
        userSidChanged(state, action) {
            state.userSid = action.payload
            sessionStorage.setItem("userSid", state.userSid)
        },
        passwordSetEnabledChanged(state, action) {
            state.passwordSetEnabled = action.payload
            sessionStorage.setItem("passwordSetEnabled", state.passwordSetEnabled.toString())
        }, 
        passwordSetRequiredChanged(state, action) {
            state.passwordSetRequired = action.payload
            sessionStorage.setItem("passwordSetRequired", state.passwordSetRequired.toString())
        },
        serverOriginNameChanged(state, action) {
            state.serverOriginName = action.payload
            sessionStorage.setItem("serverOriginName", state.serverOriginName)
        },
        sessionValidTimeChanged(state, action: PayloadAction<{ valid_to: string, current_timestamp: string }>) {
            const currentTimestamp = new Date(action.payload.current_timestamp).getTime();
            const sessionValidToTimestamp = new Date(action.payload.valid_to).getTime();
            const differenceInMinutes = Math.floor((sessionValidToTimestamp - currentTimestamp) / (1000 * 60));
            
            state.sessionValidTime = differenceInMinutes.toString();
            state.sessionValidTo = toServerFmt(moment().add(differenceInMinutes,'minutes'));

            sessionStorage.setItem("sessionValidTime", state.sessionValidTime ?? "");
            sessionStorage.setItem("sessionValidTo", state.sessionValidTo ?? '');
        },
        selectedInstallationChanged(state, action: PayloadAction<SelectedInstallation>) {
            state.selectedInstallation = action.payload;
            localStorage.setItem('selectedInstallation', JSON.stringify(action.payload));
        },
        googleApiKeyChanged(state, action: PayloadAction<string>) {
            state.googleApiKey = action.payload;
            localStorage.setItem('googleApiKey', state.googleApiKey);
        }
    }
})

window.addEventListener('beforeclose', () => {
    localStorage.removeItem('rootFolder');
});


export const { 
    resetSession,
    userNameChanged,
    sessionIdChanged,
    installationIdChanged,
    installationNameChanged,
    targetInstallationChanged,
    activePageChanged,
    isAuthenticatedChanged,
    isSystemUserChanged,
    userSidChanged,
    passwordSetEnabledChanged,
    passwordSetRequiredChanged,
    serverOriginNameChanged,
    sessionValidTimeChanged,
    selectedInstallationChanged,
    googleApiKeyChanged
} = sessionSlice.actions

export default sessionSlice.reducer

// selectors
export const selectUserName = (state: RootState) => state.session.userName
export const selectSessionId = (state: RootState) => state.session.sessionId
export const selectInstallationId = (state: RootState) => state.session.installationId
export const selectInstallationName = (state: RootState) => state.session.installationName
export const selectTargetInstallation = (state: RootState) => state.session.targetInstallation
export const selectActivePage = (state: RootState) => state.session.activePage
export const selectIsAuthenticated = (state: RootState) => state.session.isAuthenticated
export const selectIsSystemUser = (state: RootState) => state.session.isSystemUser
export const selectUserSid = (state: RootState) => state.session.userSid
export const selectPasswordSetEnabled = (state: RootState) => state.session.passwordSetEnabled;
export const selectPasswordSetRequired = (state: RootState) => state.session.passwordSetRequired;
export const selectServerOriginName = (state: RootState) => state.session.serverOriginName;
export const selectSessionValidTime = (state: RootState) => state.session.sessionValidTime;
export const selectSessionValidTo = (state: RootState) => state.session.sessionValidTo;
export const selectSelectedInstallation = (state: RootState) => state.session.selectedInstallation;
export const selectGoogleApiKey = (state: RootState) => state.session.googleApiKey;
