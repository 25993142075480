import { configureStore } from '@reduxjs/toolkit'

import sessionSlice from './features/session/sessionSlice'
import notificationsSlice from './features/notifications/notificationsSlice'
import { emptySplitApi } from './openapi/emptyApi'
import sessionMiddleware from './features/session/sessionMiddleware'

const store = configureStore({
    reducer: {
        session: sessionSlice,
        notification: notificationsSlice,
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['notification.onYes', 'notification.onHide'],
        ignoredActionPaths: ['payload.message', 'payload.onYes', 'payload.onHide', 'meta.arg.originalArgs.body'],
      },
    })
    .concat(sessionMiddleware).concat(emptySplitApi.middleware)
})
export type RootState = ReturnType<typeof store.getState>

export default store
