import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useSessionCreateMutation, useSessionDeleteMutation, useSessionRefreshMutation } from '../features/session/sessionApiSlice';
import { installationNameChanged, selectInstallationName, selectSessionId } from '../features/session/sessionSlice';
import { useAuthenticationPublicPlanTokenMutation } from '../features/plans-public/plansPublicApiSlice';
import { useRelationPlanPublicQuery, useRelationPublicFileListQuery, useRelationPublicFileTextListQuery } from '../features/plans-public/plansPublicApiSlice';
import { serverToLocalDate } from '../utils/DateTime';
import PublicFileAudioPlayer from './PublicFileAudioPlayer';
import { RelationFile, RelationPlansPublic} from '../openapi/citywebApi';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import "../style/reportPageStyle.css";

const ReportsPage: React.FC = () => {
  // store access
  const dispatch = useDispatch();
  const storedSessionId = useSelector(selectSessionId);
  const storedInstallationName = useSelector(selectInstallationName);

  // local state
  const { hashedLocalityName } = useParams<{ hashedLocalityName: string }>();
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [installationName, setInstallationName] = useState<string | null>(null);
  const [selectedRelation, setSelectedRelation] = useState<number | null>(null);
  const [activeEventKey, setActiveEventKey] = useState<string | null>(null);
  const isMounted = useRef(true);

  // API access
  const [createSession] = useSessionCreateMutation();
  const [authenticationLogin] = useAuthenticationPublicPlanTokenMutation();
  const [deleteSession] = useSessionDeleteMutation();
  const [refreshSession] = useSessionRefreshMutation();

  const {
    data: publicPlans,
    isLoading: isLoadingPublicPlans,
    isFetching: isFetchingPublicPlans,
  } = useRelationPlanPublicQuery(
    { sessionId: storedSessionId ?? '' },
    { skip: !storedSessionId || !isAuthenticated }
  );

  const {
    data: publicFiles,
    isLoading: isLoadingPublicFiles,
    isFetching: isFetchingPublicFiles,
  } = useRelationPublicFileListQuery(
    { sessionId: storedSessionId ?? '', relationId: selectedRelation ?? 0 },
    { skip: !storedSessionId || !isAuthenticated || !selectedRelation }
  );

  const allFilesId: number[] = publicFiles ? publicFiles.map((file: RelationFile) => file.id) : [];
  const allFilesName: string[] = publicFiles ? publicFiles.map((file: RelationFile) => file.file_name) : [];
  const allFilesOrder: { [key: number]: number } = publicFiles ? publicFiles.reduce((acc: { [key: number]: number }, file: RelationFile) => {  acc[file.id] = file.order_position; return acc; }, {}) : {};

  const {
    data: publicFileTexts,
    isLoading: isLoadingPublicFileTexts,
    isFetching: isFetchingPublicFileTexts,
  } = useRelationPublicFileTextListQuery(
    { sessionId: storedSessionId ?? '', relationId: selectedRelation ?? 0, ids: allFilesId?.toString() ?? '' },
    { skip: !storedSessionId || !isAuthenticated || !selectedRelation || !allFilesId }
  );

  useEffect(() => {
    return () => { isMounted.current = false; };
  }, []);

  async function processLogin() {
    let sessionId = storedSessionId;

    setLoginInProgress(true);
    setInstallationName(null);

    // create session
    if (!sessionId) {
      try {
        const session = await createSession().unwrap();
        sessionId = session.sid;
      } catch (error) { }
    }

    if (sessionId) {
      try {
        // authenticate
        const authentication = await authenticationLogin({ sessionId, userLoginToken: { key: hashedLocalityName || "" } }).unwrap();
        dispatch(installationNameChanged(authentication.installation_name));
        setIsAuthenticated(true);
        setInstallationName(authentication.installation_name);
      }
      catch (error) {
        // delete session
        try {
          await deleteSession({ sessionId });
          setIsAuthenticated(false);
        }
        catch (error) { }
      }
    }
      setLoginInProgress(false);
  }

  useEffect(() => {
    processLogin();
  }, [hashedLocalityName]);

  const handleClick = (plan: any) => {
    if (selectedRelation !== plan.relation_id) {
      setSelectedRelation(plan.relation_id);
    }
  };

  function CustomToggleText({ children, eventKey }: any) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className='toggle-text-button'
      >
        {children}
      </button>
    );
  }

  function CustomToggleAudio({ children, eventKey }: any) {
    const decoratedOnClick = useAccordionButton(eventKey);
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className='toggle-audio-button'
      >
        {children}
      </button>
    );
  }

  const handleAccordionToggle = (eventKey: AccordionEventKey) => {
    setActiveEventKey(activeEventKey === eventKey ? null : (eventKey as string));
  };

  const getLatestPlans = (plans: RelationPlansPublic[]) => {
    const latestPlansMap = plans.reduce((acc: { [key: number]: RelationPlansPublic }, plan) => {
      const existingPlan = acc[plan.relation_id];
      if (!existingPlan || new Date(plan.start_utc) > new Date(existingPlan.start_utc)) {
        acc[plan.relation_id] = plan;
      }
      return acc;
    }, {});
  
    return Object.values(latestPlansMap).sort((a, b) => new Date(b.start_utc).getTime() - new Date(a.start_utc).getTime());
  };

  const getPlanAllDates = (planId: number) => {
    const allDates = publicPlans
      ?.filter((publicPlan: RelationPlansPublic) => publicPlan.relation_id === planId)
      .map((publicPlan: RelationPlansPublic) => serverToLocalDate(publicPlan.start_utc));
    return allDates ?? [];
  };

  const latestPublicPlans = publicPlans ? getLatestPlans(publicPlans) : [];

  useEffect(() => {
    if(isAuthenticated) {
      const interval = setInterval(() => {
        refreshSession({sessionId: storedSessionId ?? ''});
      }, 900000); //15min
      return () => clearInterval(interval);
    }
  }, [isAuthenticated]);

  const isSomethingLoading =
    loginInProgress ||
    isLoadingPublicPlans ||
    isFetchingPublicPlans;
    
  const isDataLoading =
    isLoadingPublicFiles ||
    isFetchingPublicFiles ||
    isLoadingPublicFileTexts ||
    isFetchingPublicFileTexts;

  return (
    <div className="reports-page-container">
      { isAuthenticated && !isSomethingLoading ? (
        <div className="authenticated-content">
          <h1 className="header-title-bold">
            {installationName}
          </h1>
          <h4 className="header-title">
              {latestPublicPlans?.length === 0 ? "Žádná hlášení" : "Poslední hlášení rozhlasu"}
          </h4>
          <div >
            <Accordion defaultActiveKey="0" activeKey={activeEventKey} onSelect={handleAccordionToggle}>
              {latestPublicPlans?.map((plan: RelationPlansPublic) => (
                <Card key={plan.plan_id} style={{ marginTop: '30px' }}>
                  <Card.Header>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                      <div style={{ flex: 1, textAlign: 'left' }}>
                      <div className='header-title-bold'>
                          Vysíláno:
                          {getPlanAllDates(plan?.relation_id ?? 0).map((date, index) => (
                            <div key={index} style={{margin:'2px 0'}}>{date}</div>
                          ))}
                        </div>
                        {installationName !== plan.installation_name && (
                        <div className='header-title'>Vysíláno z: {`${plan.installation_name}`} </div>
                        )}
                        <p>{plan.plan_name}</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                        {plan.has_speech_text === 1 && (
                          <CustomToggleText eventKey={`text-${plan?.plan_id.toString()}`}>
                            <div onClick={() => handleClick(plan)}>Text</div>
                          </CustomToggleText>
                        )}
                        <CustomToggleAudio eventKey={`audio-${plan?.plan_id.toString()}`}>
                           <div onClick={() => handleClick(plan)}>Přehrát</div>
                        </CustomToggleAudio>
                      </div>
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey={`text-${plan.plan_id.toString()}`}>
                  <Card.Body>
                    <div style={{ marginTop: '10px'}}>
                      {!isDataLoading && publicFileTexts ? 
                        [...publicFileTexts]
                          .sort((a, b) => allFilesOrder[a.id] - allFilesOrder[b.id])
                          .map((text, index, array) => (
                            <div key={text.id} style={{ textAlign: 'left' }}>
                              <h6 className="header-title-bold">{text.file_name.replace('.mp3', '')}</h6>
                              <p>{text.speech_text?.trim() !== "" ? text.speech_text : "---"}</p>
                              {index < array.length - 1 && <hr />}
                            </div>
                          )) 
                        : "Načítám texty..."}
                    </div>
                  </Card.Body>
                  </Accordion.Collapse>
                  <Accordion.Collapse eventKey={`audio-${plan.plan_id.toString()}`}>
                    {!isDataLoading && publicFiles ? (
                      <Card.Body>
                        <PublicFileAudioPlayer 
                          allFilesId={allFilesId}
                          allFilesName={allFilesName}
                          allFilesOrder={allFilesOrder}
                          relationId={plan.relation_id}
                          isOpen={activeEventKey === `audio-${plan.plan_id.toString()}`} />
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <div>Načítám soubory...</div>
                      </Card.Body>
                    )}
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </div>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          marginTop: '0',
          backgroundColor: 'WhiteSmoke !important',
          padding: '20px',
          overflowY: 'auto',
          boxSizing: 'border-box',
        }}>
        {(isSomethingLoading) ? (
          <h1 style={{ color: '#0047b0', marginBottom: '10px', fontWeight: "bold" }}>
             Načítám data...
          </h1>
        ) : (
          <h1 style={{ color: '#0047b0', marginBottom: '10px', fontWeight: "bold" }}>
            Neplatná Licence
          </h1>
        )}
        </div>
      )
      }
    </div>
  );
};

export default ReportsPage;
