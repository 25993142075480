import { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'

import { setTokensLocal } from '../context/auth'
import { setErrorMessage } from '../features/notifications/notificationsSlice'
import { resetSession } from '../features/session/sessionSlice'


const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }):
BaseQueryFn<
    {
        url: string,
        method?: AxiosRequestConfig['method'],  // request method
        body?: AxiosRequestConfig['data']      // request body
    }
> => 
async ({ url, method, body }, { dispatch, getState }) => {
    
    // file uploads flag
    const isFileUpload = url.startsWith('/api/relation/file/upload') ? true : false

    const headers = isFileUpload ? { 'Content-Type': 'audio/*' } : undefined

    // remove '/null' parts of url (omit null values in parenthesis)
    // test string: /api/param1/value1/(param2/null)/param3/value3/param4/null/(param5/null)/param6/value6/(null/value7)/(param8/null)
    const regEx = /\(([^/]*?)(\/null)\)/gi
    const urlWithoutNullValues = url.replaceAll(regEx, '($1)')

    try {
        const result = await axios({ 
            url: baseUrl + urlWithoutNullValues,
            method: method ?? 'GET', 
            //data: isFileUpload ? body : qs.stringify(body),
            data: body,
            headers
        })
        return { data: result.data }
    } 
    catch (axiosError) 
    {
        let error = axiosError as AxiosError
        
        if (error.response) {
            // server responded, status code out of range 2xx
            if (error.response.status === 401) {
 
                // logout
                setTokensLocal(null)
                dispatch(resetSession())

                return {
                    error: { status: 401 }
                }                  
            }
            else
            return {
                error: { 
                    status: error.response.status, 
                    data: error.response.data 
                }
            }
        }
        else if (error.request) {
            // no response was received
            dispatch(setErrorMessage('Chyba spojení se serverem'))
            return {
                error: { status: 0 }
            }            
        }
        else {
            // unknown error
            dispatch(setErrorMessage('Neznámá chyba'))
            return {
                error: { status: 0 }
            }             
        }
    }
}   

export default axiosBaseQuery
