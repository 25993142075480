import React from 'react';

const MainPage = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      margin: 0,
      backgroundColor: '#e2e2e2'
    }}>
      <h1 style={{ color: '#0047b0', fontSize: "15rem", fontWeight: "bold", margin: 0 }}>
        BMIS
      </h1>
      <h1 style={{ color: '#0047b0', margin: '-1rem'}}>
        Bezdrátové místní informační systémy
      </h1>
    </div>
  );
};

export default MainPage;
