import { setErrorMessage } from '../features/notifications/notificationsSlice'

import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'


export type ErrorInfo = QueryReturnValue<any, { status: number, value: any }, {}>

export const processError = (error: any, dispatch: ThunkDispatch<any, any, AnyAction>, msg: string) => {
    const { error: queryError } = error as ErrorInfo
    if (queryError?.status !== 0) {               
        let message = queryError?.status === 401
            ? 'Z důvodu nečinnosti jste byli odhlášeni'
            : msg
        dispatch(setErrorMessage(message))
    }
}
