import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './components/MainPage';
import InstallationSelectPage from './components/InstallationSelectPage';
import ReportsPage from './components/ReportsPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/hlaseni/installation-selection" element={<InstallationSelectPage />} />
        <Route path="/hlaseni/:hashedLocalityName" element={<ReportsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
