import { createSelector } from '@reduxjs/toolkit'

import { citywebApi } from '../../openapi/citywebApi'
import { 
    userNameChanged, 
    installationNameChanged, 
    targetInstallationChanged,
    isAuthenticatedChanged,
    isSystemUserChanged,
    userSidChanged,
    passwordSetEnabledChanged,
    passwordSetRequiredChanged,
    selectTargetInstallation,
    serverOriginNameChanged,
    sessionValidTimeChanged,
} from './sessionSlice'
import { setTokensLocal } from '../../context/auth'
import { sessionIdChanged, resetSession } from './sessionSlice'
import { ErrorInfo, processError } from '../../utils/errors'
import { selectInstallationId } from './sessionSlice'
import { RootState } from '../../store'
import { setErrorMessage } from '../notifications/notificationsSlice'
import citywebApiTags from '../../openapi/citywebApiTags'

// generated API enhancement
export const sessionApiSlice = citywebApi.enhanceEndpoints({
    addTagTypes: [citywebApiTags.sessionPermissions],
    endpoints: {
        sessionCreate: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const token = data
                    const sid = data.sid.trim()
                    setTokensLocal(token)
                    dispatch(sessionIdChanged(sid))
                    dispatch(sessionValidTimeChanged({ valid_to: data.valid_to, current_timestamp: data.current_timestamp }))
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se vytvořit session')
                }
            }
        },
        authenticationLogin: {
            async onQueryStarted(arg, { dispatch, queryFulfilled }) { 
                try {
                    const { data: { first_name, surname, type: user_type, sid:userSid, password_set_enabled, password_set_required } } = await queryFulfilled
                    const userName = `${first_name} ${surname}`
                    dispatch(isAuthenticatedChanged(true))
                    dispatch(isSystemUserChanged(user_type === 'S'))
                    dispatch(userNameChanged(userName))
                    dispatch(userSidChanged(userSid))
                    dispatch(installationNameChanged(arg.installationName))
                    dispatch(passwordSetEnabledChanged(password_set_enabled))
                    dispatch(passwordSetRequiredChanged(password_set_required))
                    localStorage.setItem('installation_name', arg.installationName);
                    sessionStorage.setItem('user', userName);
                } catch (error) {
                    const e = error as ErrorInfo
                    if (e.error?.status === 401)
                        dispatch(setErrorMessage('Zadejte správné uživatelské jméno a heslo'))
                    else
                        processError(error, dispatch, 'Přihlášení se nezdařilo')
                }
            }             
        },
        sessionDelete: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) { 
                try {
                    await queryFulfilled
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se odstranit session')
                }
                setTokensLocal(null)
                dispatch(resetSession())
            }             
        },
        installationList: {
            async onQueryStarted(_, { getState, dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    const installationId = selectInstallationId(getState() as unknown as RootState)
                    const targetInstallation = selectTargetInstallation(getState() as unknown as RootState)
                    const installation = data.find(inst => inst.id === installationId)
                    const installationTarget = data.find(inst => inst.name === targetInstallation?.name)
                    if(installationTarget) 
                        dispatch(targetInstallationChanged(installationTarget))
                    else if (installation)
                        dispatch(targetInstallationChanged(installation))
                    else if (data.length > 0) 
                        dispatch(targetInstallationChanged(data[0]))
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se načíst seznam instalací')
                }
            },
            providesTags: [citywebApiTags.sessionPermissions],
        },
        installationsGuess: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se načíst seznam všech instalací')
                }
            }
        },
        installationListAttachSystem: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se načíst seznam všech instalací')
                }
            }
        },            
        sessionPermissionList: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se načíst seznam oprávnění')
                }                
            },
            providesTags: [citywebApiTags.sessionPermissions],
        }, 
        authenticationRefeshAccess: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se aktualizovat seznam oprávnění')
                }                
            },
            invalidatesTags: [citywebApiTags.sessionPermissions],
        },
        installationsGuessUserOnly: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se načíst seznam instalací')
                }
            }
        },
        serverOriginName: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(serverOriginNameChanged(data.name))
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se načíst název serveru')
                }
            }
        },
        sessionRefresh: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(sessionValidTimeChanged({ valid_to: data.valid_to, current_timestamp: data.current_timestamp}))
                } catch (error) {
                    processError(error, dispatch, 'Nepodařilo se obnovit session')
                }
            }
        },
    }
})

export const {
    useSessionCreateMutation,
    useSessionDeleteMutation,
    useAuthenticationLoginMutation,
    useInstallationListQuery,
    useLazyInstallationsGuessQuery,
    useSessionPermissionListQuery,
    useInstallationsGuessQuery,
    useInstallationListAttachSystemQuery,
    useAuthenticationRefeshAccessMutation,
    useLazyInstallationsGuessUserOnlyQuery,
    useServerOriginNameQuery,
    useSessionRefreshMutation,
} = sessionApiSlice

// selectors

// installations
export const selectInstallationsResult = (args: any) => sessionApiSlice.endpoints.installationList.select(args)
const emptyInstallations: any[] = []

export const selectAllInstallations = (args: any) => createSelector(
    (state: any) => selectInstallationsResult(args)(state),
    installationsResult => installationsResult?.data ?? emptyInstallations
)

// permissions
export const selectPermissionsResult = (args: any) => sessionApiSlice.endpoints.sessionPermissionList.select(args)
const emptyPermissions: any[] = []

export const selectAllPermissions = (args: any) => createSelector(
    (state: any) => selectPermissionsResult(args)(state),
    permissionsResult => permissionsResult?.data ?? emptyPermissions
    )
export const makeSelectAllowedInstallationsByAccessName = (accessNameFor: string[], installationId: number, args: any) => createSelector(
    (state: any) => selectPermissionsResult(args)(state),
    installationsResult => installationsResult?.data
     ?.filter(p => (accessNameFor.some((accessItem) => p.access_name === accessItem) && p.installation_id === installationId ))
    ?.map(p => p.for_installation_id)
    .join(',') ?? `${installationsResult.data !== undefined}`
)


