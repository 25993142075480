import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSessionId } from '../features/session/sessionSlice';
import { IoPlayBackSharp, IoPlayForwardSharp } from "react-icons/io5";
import "../style/audioPlayerStyle.css";

interface AudioPlayerProps {
  allFilesId: number[];
  allFilesName: string[];
  allFilesOrder: { [key: number]: number };
  relationId: number;
  isOpen: boolean;
}

const PublicFileAudioPlayer: React.FC<AudioPlayerProps> = ({ allFilesId, allFilesName, allFilesOrder, relationId, isOpen }) => {
  const sessionId = useSelector(selectSessionId);
  const baseApiUrl = window.location.origin;

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioUrls, setAudioUrls] = useState<string[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);

  // Stažení všech souborů při změně relationId
  useEffect(() => {
    const downloadAllFiles = async () => {
      const urls: string[] = [];
      if (allFilesId) {
        for (const fileId of allFilesId) {
          const fileUrl = `${baseApiUrl}/api/relation/public/file/download/session_id/${sessionId}/relation_id/${relationId}/file_id/${fileId}`;
          try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            if (blob.size > 0 && blob.type.includes('audio')) {
              const audioBlobUrl = URL.createObjectURL(blob);
              urls.push(audioBlobUrl);
            }
          } catch (error) {}
        }
      }
      const sortedUrls = urls.sort((a, b) => allFilesOrder[allFilesId[urls.indexOf(a)]] - allFilesOrder[allFilesId[urls.indexOf(b)]]);
      setAudioUrls(sortedUrls); 
      setCurrentFileIndex(0);
    };

    if (isOpen) {
      downloadAllFiles();
    } else {
      //clean pri zavreni accordion
      setAudioUrls([]);
      setCurrentFileIndex(0);
      audioRef.current?.pause();
    }
  }, [relationId, allFilesId]);

  const playFileAtIndex = (index: number) => {
    if (index < audioUrls.length) {
      const fileUrl = audioUrls[index];
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = fileUrl;
        audioRef.current.play();
        setCurrentFileIndex(index);
      }
    }
  };

  useEffect(() => {
    const handleEnded = () => {
      if (currentFileIndex < audioUrls.length - 1) {
        playFileAtIndex(currentFileIndex + 1);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleEnded);
      return () => {
        audioRef.current?.removeEventListener('ended', handleEnded);
      };
    }
  }, [currentFileIndex, audioUrls]);

  return (
    <div>
      <h6 className='header-text-color'>{allFilesName[currentFileIndex].replace('.mp3', '')}</h6>
      <audio ref={audioRef} controls controlsList="nodownload noplaybackrate" className="transparent-audio">
        {audioUrls.map((url, index) => (
          <source key={index} src={url} type="audio/mpeg" />
        ))}
      </audio>
      <div>
      <button className='audio-button'
        onClick={() => playFileAtIndex(currentFileIndex - 1)} 
        disabled={currentFileIndex === 0}>
          <IoPlayBackSharp />
      </button>
      <span>{`(${currentFileIndex+1}/${audioUrls.length})`}</span>
      <button className='audio-button' 
        onClick={() => playFileAtIndex(currentFileIndex + 1)} 
        disabled={currentFileIndex === audioUrls.length - 1}>
          <IoPlayForwardSharp />
      </button>
      </div>
      </div>
  );
};

export default PublicFileAudioPlayer;
