import { Middleware } from 'redux';
import { sessionValidTimeChanged } from './sessionSlice';
import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';


const sessionValidTimeChangedAsync = (newSessionValidTo: { valid_to: string, current_timestamp: string }) => {
    return (dispatch: Dispatch<AnyAction>) => {
      setTimeout(() => {
        dispatch(sessionValidTimeChanged(newSessionValidTo));
      }, 0);
    };
  };


const updateSessionValidToMiddleware: Middleware = (store) => (next) => (action) => {

    let value = action?.payload?.queryCacheKey;
    const ignoredAction = value && value.startsWith('mqttWebsocketGenerateOneTimeSessionPwd') || 
      value && value.startsWith('mqttInternalWebsocketTopicList');
      
    if ((ignoredAction !== undefined && ignoredAction === false)){
        const state = store.getState();

        const sessionValidTo = new Date(new Date().getTime() + 20 * 60000).toISOString();

        const newSessionValidTo = { valid_to: sessionValidTo , current_timestamp: new Date().toISOString()}

        store.dispatch(sessionValidTimeChangedAsync(newSessionValidTo) as any);
    }

    return next(action);
};

export default updateSessionValidToMiddleware;
