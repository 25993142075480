import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from '../services/axiosBaseQuery'

const apiUrl = document.location.origin

export const emptySplitApi = createApi({
    reducerPath: 'logovaniReduxu',        //TODO: logging redux toolkit
    baseQuery: axiosBaseQuery({
        baseUrl: apiUrl
    }),
    endpoints: () => ({})
})
